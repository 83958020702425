import {
  authHttpClient,
  serverErrorToErrorList,
} from "../../services/httpClient";
import { CLEAR_SERVER_ERROR, SET_SERVER_ERROR } from "../actionTypes";

export const changePassword = (data) => async (dispatch) => {
  try {
    let response = await authHttpClient.post(`/change-password`, data);

    return response.data;
  } catch (e) {
    dispatch({
      type: SET_SERVER_ERROR,
      payload: { errors: serverErrorToErrorList(e) },
    });
    throw e;
  }
};

export const forgotPassword = (email) => async (dispatch) => {
  try {
    let response = await authHttpClient.post(`/forgot-password`, email);

    dispatch({ type: CLEAR_SERVER_ERROR });

    return response.data;
  } catch (e) {
    dispatch({
      type: SET_SERVER_ERROR,
      payload: { errors: serverErrorToErrorList(e) },
    });
    throw e;
  }
};

export const resetPassword = (data) => async (dispatch) => {
  try {
    let response = await authHttpClient.post(`/reset-password`, data);

    dispatch({ type: CLEAR_SERVER_ERROR });

    return response.data;
  } catch (e) {
    dispatch({
      type: SET_SERVER_ERROR,
      payload: { errors: serverErrorToErrorList(e) },
    });
    throw e;
  }
};
