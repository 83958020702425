import { CLEAR_SERVER_ERROR, SET_SERVER_ERROR } from "../actionTypes";

const serverErrorReducer = (state = { errors: [] }, action) => {
  switch (action.type) {
    case SET_SERVER_ERROR:
      return action.payload;
    case CLEAR_SERVER_ERROR:
      return { errors: [] };
    default:
      return state;
  }
};

export default serverErrorReducer;
