export const phoneNumber = (value) =>
  value &&
  !/^(?=(?:\D*\d){10,15}\D*$)\+?[0-9]{1,3}[\s-]?(?:\(0?[0-9]{1,5}\)|[0-9]{1,5})[-\s]?[0-9][\d\s-]{5,7}\s?(?:x[\d-]{0,4})?$/g.test(
    value
  )
    ? "Ungültige Telefonnummer"
    : undefined;

const strongRegex = new RegExp(
  "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
);

export const email = (value) =>
  value &&
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    value
  )
    ? undefined
    : "Ungültige E-Mail-Adresse.";

export const required = (value) => (value ? undefined : "Erforderlich.");

export const requiredAndEmail = (value) =>
  value ? email(value) : "Erforderlich";
export const requiredAndPassword = (value) =>
  value ? password(value) : "Erforderlich";

export const password = (value) =>
  value && !strongRegex.test(value)
    ? "Das Passwort muss mindestens einen Großbuchstaben, einen Kleinbuchstaben, ein Sonderzeichen (@, #, $) und einen numerischen Wert enthalten. Es muss 8 Stellen lang sein."
    : undefined;
export const same = (value1, value2) =>
  value1 === value2
    ? "Passwort und Passwortbestätigung sollten übereinstimmen."
    : undefined;
