import { AUTHENTICATE, DEAUTHENTICATE } from "../actionTypes";

const authReducer = (state = null, action) => {
  switch (action.type) {
    case AUTHENTICATE:
      return action.payload;
    case DEAUTHENTICATE:
      return null;
    default:
      return state;
  }
};

export default authReducer;
