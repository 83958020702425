import React, { Component } from "react";
import { Link } from "react-router-dom";

class PartnerListItem extends Component {
  render() {
    const { data } = this.props;

    return (
      <div className="card" style={{ width: "16rem" }}>
        <div
          className="card-img-top"
          style={{
            background: `url(${data.shop_logo_link})`,
            backgroundSize: "cover",
          }}
        ></div>
        <div className="card-body">
          <h5 className="card-title">{data.shop_name}</h5>
          <div style={{ minHeight: "150px" }}>
            <p className="card-text">{data.description}</p>
          </div>
          <Link
            to={`/partner-detail?id=${data.id}`}
            className="btn btn-primary"
          >
            Ansicht
          </Link>
        </div>
      </div>
    );
  }
}

export default PartnerListItem;
