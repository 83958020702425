import { FETCH_CODES, SET_SERVER_ERROR } from "../actionTypes";
import {
  authHttpClient,
  serverErrorToErrorList,
} from "../../services/httpClient";

export const fetchCouponList = (params = {}) => async (dispatch) => {
  try {
    let response = await authHttpClient.get(`/coupons`, { params: params });

    dispatch({
      type: FETCH_CODES,
      payload: response.data,
    });

    return response.data;
  } catch (e) {
    dispatch({
      type: SET_SERVER_ERROR,
      payload: { errors: serverErrorToErrorList(e) },
    });
    throw e;
  }
};
