import { FETCH_CATEGORY_LIST, SET_SERVER_ERROR } from "../actionTypes";
import httpClient, { serverErrorToErrorList } from "../../services/httpClient";

export const fetchCategoryList = (params = {}) => async (dispatch) => {
  try {
    let response = await httpClient.get(`/categories`, { params: params });

    dispatch({
      type: FETCH_CATEGORY_LIST,
      payload: response.data.data,
    });

    return response.data.data;
  } catch (e) {
    dispatch({
      type: SET_SERVER_ERROR,
      payload: { errors: serverErrorToErrorList(e) },
    });
    throw e;
  }
};
