import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import { inputField } from "../redux_form_fields/inputField";
import { requiredAndEmail } from "../../services/validate";
import { forgotPassword } from "../../redux/actions/passwordActions";
import { connect } from "react-redux";
import { showFlashMessage } from "../../redux/actions/flashMessageActions";
import FlashMessage from "../common/FlashMessage";

class ForgotPassword extends Component {
  handleSubmit = (formProps) => {
    this.props.forgotPassword(formProps).then(
      (response) => {
        this.props.showFlashMessage("Wir haben Ihnen eine E-Mail gesendet.");
      },
      (errorResponse) => {}
    );
  };

  render() {
    const { handleSubmit } = this.props;
    return (
      <section className="login-page">
        <div className="container">
          <FlashMessage />
          <div className="auth-block">
            <h5 className="auth-title">Password ändern</h5>
            <form onSubmit={handleSubmit(this.handleSubmit)}>
              <div className="form-group">
                <label>E-Mail-Adresse</label>
                <Field
                  className="form-control"
                  type="email"
                  component={inputField}
                  name="email"
                  validate={requiredAndEmail}
                />
              </div>
              <div className="form-group">
                <button className="btn btn-primary btn-block" type="submit">
                  Ändern
                </button>
              </div>
            </form>
          </div>
        </div>
      </section>
    );
  }
}

ForgotPassword = reduxForm({
  form: "forgot-password",
})(ForgotPassword);

export default connect(null, { forgotPassword, showFlashMessage })(
  ForgotPassword
);
