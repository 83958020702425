import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const CarouselSlide = (props) => {
  const { shops } = props;

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 6,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 6,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 4,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2,
    },
  };
  if (shops?.length === 0) {
    return null;
  }
  let shopLogo = shops
    .map((shop) => shop.shop_logo_link)
    .filter((shop) => shop !== null);
  return (
    <>
      <Carousel
        ssr
        partialVisbile
        itemClass="image-item"
        responsive={responsive}
      >
        {shopLogo.map((link) => (
          <div className="logo-item" key={link}>
            <img className="partner-logo-item" src={link} alt="" />
          </div>
        ))}
      </Carousel>
    </>
  );
};

export default CarouselSlide;
