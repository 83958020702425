import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import store from "./redux";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Landing from "./components/pages/Landing";
import NotFound404 from "./components/pages/NotFound404";
import App from "./components/App";
import AGBS from "./components/static_pages/AGBS";
import DataProtection from "./components/static_pages/DataProtection";
import FAQ from "./components/static_pages/FAQ";
import Imprint from "./components/static_pages/Imprint";
import TermsAndConditions from "./components/static_pages/TermsAndConditions";
import Partners from "./components/pages/Partners";
import Notification from "./components/pages/Notification";
import PartnerDetail from "./components/pages/PartnerDetail";
import Coupon from "./components/pages/Coupon";
import Register from "./components/pages/Register";
import Login from "./components/pages/Login";
import ForgotPassword from "./components/pages/ForgotPassword";
import Reset from "./components/pages/Reset";
import ChangePassword from "./components/pages/ChangePassword";
import MyCoupons from "./components/pages/MyCoupons";
import Checkout from "./components/pages/Checkout";
import AlertTemplate from "react-alert-template-basic";
import { positions, Provider as AlertProvider, transitions } from "react-alert";
import EmailVerification from "./components/pages/EmailVerification";

import "./assets/css/stylesheet.css";

// optional cofiguration
const options = {
  // you can also just use 'bottom center'
  position: positions.TOP_CENTER,
  timeout: 5000,
  offset: "30px",
  // you can also just use 'scale'
  transition: transitions.SCALE,
};
ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <AlertProvider template={AlertTemplate} {...options}>
        <App>
          <Switch>
            <Route path={"/"} exact component={Landing} />
            <Route path={"/partners"} exact component={Partners} />
            <Route path={"/partner-detail"} exact component={PartnerDetail} />
            <Route path={"/coupon"} exact component={Coupon} />
            <Route path={"/notification"} exact component={Notification} />
            <Route path={"/register"} exact component={Register} />
            <Route path={"/login"} exact component={Login} />
            <Route path={"/forgot-password"} exact component={ForgotPassword} />
            <Route path={"/reset-password"} exact component={Reset} />
            <Route path={"/change-password"} exact component={ChangePassword} />
            <Route path={"/my-coupons"} exact component={MyCoupons} />
            <Route path={"/checkout"} exact component={Checkout} />
            <Route
              path="/email-verification"
              exact
              component={EmailVerification}
            />

            <Route path="/agbs" exact component={AGBS} />
            <Route path="/data-protection" exact component={DataProtection} />
            <Route path="/faq" exact component={FAQ} />
            <Route path="/imprint" exact component={Imprint} />
            <Route
              path="/terms-and-conditions"
              exact
              component={TermsAndConditions}
            />

            <Route component={NotFound404} />
          </Switch>
        </App>
      </AlertProvider>
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);
