import { FETCH_PARTNER_DETAIL, FETCH_PARTNER_LIST } from "../actionTypes";

export const partnerListReducer = (state = [], action) => {
  switch (action.type) {
    case FETCH_PARTNER_LIST:
      return action.payload;
    default:
      return state;
  }
};

export const partnerDetailReducer = (state = null, action) => {
  switch (action.type) {
    case FETCH_PARTNER_DETAIL:
      return action.payload;
    default:
      return state;
  }
};
