import React, { Component } from "react";
import withLayout from "../common/withLayout";
class Imprint extends Component {
  render() {
    return (
      <section className="imprint-page">
        <div className="container">
          <h4 className="auth-title">IMPRESSUM</h4>
          <div className="row">
            <div className="col-md-6">
              <ul className="list-group">
                <li>Appartmentvermittlung Familie Clausen GmbH</li>
                <li>Am Buttgraben 6a 25992 List</li>
                <li>E-Mail | info@listinfo.de</li>
                <li>
                  Vertretungsberechtigte Geschäftsführer: Cornelia oder Jörn
                  Clausen
                </li>
              </ul>
            </div>
            <div className="col-md-6">
              <ul>
                <li>Registergericht: Amtsgericht Flensburg</li>
                <li>Registernummer: HRB 10714 FL</li>
                <li>Umsatzstuer-ID gem. §27a UStG: DE297105589 Inhaltlich</li>
                <li>Verantwortliche: Cornelia Clausen (Anschrift s.o.)</li>
              </ul>
            </div>
            <div className="col-md-12 mt-3">
              <h5 className="auth-title">
                Allgemeine inhaltliche Verantwortung
              </h5>
              <p>
                Das Angebot von&nbsp;
                <a
                  href="https://list-marketplace.de/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  &nbsp;www.list-marketplace.de
                </a>
                &nbsp;besteht aus redaktionellen Inhalten. Es besteht eine
                inhaltliche Verantwortlichkeit von&nbsp;
                <a
                  href="https://list-marketplace.de/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  &nbsp;www.list-marketplace.de
                </a>
                &nbsp; bezüglich der redaktionellen Inhalte. Für Inhalte die uns
                Partner zur Verfügung stellen wird keine Gewähr für die
                Richtigkeit übernommen und jede Haftung ausgeschlossen.
              </p>
            </div>

            <div className="col-md-12">
              <h5 className="auth-title">Urheberrecht</h5>
              <p>
                Das Layout der Homepage, die verwendeten Bilder und Grafiken
                sowie alle übrigen Inhalte in jeder Form sind urheberrechtlich
                geschützt. Die Seiten dürfen ohne Genehmigung weder
                vervielfältigt, geändert noch verbreitet werden.
              </p>
              <p>
                Jede nicht genehmigte Verwendung von Bild- und Fotomaterial wird
                anwaltlich verfolgt!
              </p>
            </div>
            <div className="col-md-12">
              <h5 className="auth-title">Haftungshinweis</h5>
              <p>
                Bezüglich fremder Inhalte, zu denen Verknüpfungen in Form von
                externen Links bestehen gilt:&nbsp;
                <a
                  href="https://list-marketplace.de/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  www.list-marketplace.de
                </a>
                &nbsp;hat keinen Einfluss auf Inhalt und Gestaltung der
                verlinkten Seiten. Die Nutzung von Links, die zu Seiten
                außerhalb von&nbsp;
                <a
                  href="https://list-marketplace.de/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  &nbsp;www.list-marketplace.de
                </a>
                &nbsp; führen, erfolgt auf eigene Verantwortung der Nutzer.
              </p>
              <p>
                Wir vermitteln Reiseversicherungen im Status eines
                erlaubnisfreien Annexvermittlers gemäß § 34d Abs. 8 Nr. 1
                Gewerbeordnung (GewO). Beschwerdestelle bei Streitigkeiten mit
                Versicherungsvermittlern: Versicherungsombudsmann e.V., Postfach
                08 06 32, 10006 Berlin, www.versicherungsombudsmann.de
              </p>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default withLayout(Imprint);
