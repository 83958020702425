import { combineReducers } from "redux";
import serverErrorReducer from "./serverErrorReducers";
import { flashMessageReducer } from "./flashMessageReducers";
import { reducer as formReducer } from "redux-form";
import { partnerDetailReducer, partnerListReducer } from "./partnerReducers";
import { categoryListReducer } from "./categoryReducers";
import authReducer from "./authReducers";
import couponListReducer from "./couponReducers";

const rootReducer = combineReducers({
  serverError: serverErrorReducer,
  flashMessage: flashMessageReducer,
  form: formReducer,
  partnerList: partnerListReducer,
  partnerDetail: partnerDetailReducer,
  categoryList: categoryListReducer,
  authentication: authReducer,
  couponList: couponListReducer,
});

export default rootReducer;
