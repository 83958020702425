import { FETCH_CODES } from "../actionTypes";

const couponListReducer = (
  state = {
    data: [],
  },
  action
) => {
  switch (action.type) {
    case FETCH_CODES:
      return action.payload;
    default:
      return state;
  }
};

export default couponListReducer;
