export const SET_SERVER_ERROR = "SET_SERVER_ERROR";
export const CLEAR_SERVER_ERROR = "CLEAR_SERVER_ERROR";
export const SHOW_FLASH_MESSAGE = "show_flash_message";
export const HIDE_FLASH_MESSAGE = "hide_flash_message";
export const FETCH_PARTNER_LIST = "FETCH_PARTNER_LIST";
export const FETCH_PARTNER_DETAIL = "FETCH_PARTNER_DETAIL";
export const FETCH_CATEGORY_LIST = "FETCH_CATEGORY_LIST";
export const AUTHENTICATE = "AUTHENTICATE";
export const DEAUTHENTICATE = "DEAUTHENTICATE";
export const FETCH_CODES = "FETCH_CODES";
export const EMAIL_VERIFICATION_SUCCESS = "EMAIL_VERIFICATION_SUCCESS";
