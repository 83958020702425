import React, { Component } from "react";
import { connect } from "react-redux";
import {
  fetchPartnerDetail,
  fetchPartnerList,
} from "../../redux/actions/partnerActions";
import queryString from "query-string";
import { Link } from "react-router-dom";
import withLayout from "../common/withLayout";
import { withAlert } from "react-alert";
import NotFound404 from "./NotFound404";

class PartnerDetail extends Component {
  state = {
    current_partner_id: "0",
    selected_partner_id: "0",
    viewed_partner_list: [],
    is_loading: true,
  };

  async componentDidMount() {
    const { id } = queryString.parse(this.props.location.search);
    this.setState({
      current_partner_id: id,
    });
    if (this.props.partnerList.length === 0) {
      this.props.fetchPartnerList();
    }
    try {
      await this.props.fetchPartnerDetail(id);
    } catch (e) {}
    this.setState({ is_loading: false });
  }
  async componentDidUpdate(prevProps, prevState) {
    const { id } = queryString.parse(this.props.location.search);
    console.log(prevState.current_partner_id, id);
    if (prevState.current_partner_id !== id) {
      window.scrollTo(0, 0, "smooth");
      this.setState({
        current_partner_id: id,
      });
    }
  }
  handlePartnerChange = (event) => {
    this.setState({
      selected_partner_id: event.target.value,
      current_partner_id: event.target.value,
      next_shop_link:
        event.target.value === 0
          ? "#"
          : `/partner-detail?id=${event.target.value}`,
    });
    this.props.history.push(`/partner-detail?id=${event.target.value}`);
  };

  render() {
    const { selected_partner_id, is_loading, current_partner_id } = this.state;
    const { partnerList } = this.props;
    let partnerDetail = partnerList.find(
      (partner) => partner.id === Number(current_partner_id)
    );
    let currentIndex = partnerList.findIndex(
      (partner) => partner.id === Number(current_partner_id)
    );
    let nextPartnerId = partnerList[currentIndex + 1]
      ? partnerList[currentIndex + 1].id
      : null;
    let prevPartnerId = partnerList[currentIndex - 1]
      ? partnerList[currentIndex - 1].id
      : null;

    if (is_loading) {
      return (
        <section className="container text-center page-padding">
          <h1>Loading...</h1>
        </section>
      );
    }

    return partnerDetail ? (
      <div>
        <section
          className="partner-banner"
          style={{
            backgroundImage: `url(${partnerDetail.shop_banner_link})`,
          }}
        ></section>
        <div
          className="partner-logo"
          style={{ backgroundImage: `url(${partnerDetail.shop_logo_link})` }}
        ></div>
        <h2 className="partner-name">
          {partnerDetail ? partnerDetail.shop_name : ""}
        </h2>
        <div className="partner-content">
          <div className="container">
            <div className="row">
              <div className="col-sm-6">
                <h3>Beschreibung</h3>
                <p>{partnerDetail ? partnerDetail.description : ""}</p>
                <div className="business-tag d-flex" style={{ gap: "1rem" }}>
                  {partnerDetail.categories.map((category) => (
                    <div
                      key={category.id}
                      className="badge-dark rounded-pill pr-3 pl-3"
                    >
                      {category.name}
                    </div>
                  ))}
                </div>
              </div>
              <div className="col-sm-6 partner-right-image">
                <img
                  src={partnerDetail.shop_image_link}
                  alt="inside looks of shop"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6">
                <h3>Coupon</h3>
                <p>
                  Kaufen Sie für diesen Shop einen Coupon. Dafür wählen Sie den
                  Betrag aus, und bezahlen mit PayPal. Der Coupon wird Ihnen
                  dann per E-Mail zugesandt. Diesen Coupon können Sie vor Ort
                  einlösen!
                </p>
                <Link
                  className="btn btn-primary"
                  to={`/coupon?owner_id=${partnerDetail.owner_id}`}
                >
                  Coupon kaufen
                </Link>
                <div className="partner-shipping">
                  Versand möglich?
                  <strong>
                    {partnerDetail
                      ? partnerDetail.provide_shipping_service === "true"
                        ? " Ja "
                        : " Nein"
                      : ""}
                  </strong>
                </div>
              </div>
              <div className="col-sm-6">
                <h3>Wie sie uns finden</h3>
                <ul className="partner-contact-info">
                  <li>
                    <i className="icon-user-o"></i>
                    <span>
                      {partnerDetail ? partnerDetail.contact_name : ""}
                    </span>
                  </li>
                  <li>
                    <i className="icon-pin"></i>
                    <span>
                      {partnerDetail.street_address}&nbsp;
                      {partnerDetail.house_no},&nbsp;
                      {partnerDetail.postal_code},&nbsp;
                      {partnerDetail.city ? partnerDetail.city.name : ""}
                    </span>
                  </li>
                  <li>
                    <i className="icon-phone"></i>
                    <span>{partnerDetail.contact_phone_no}</span>
                  </li>
                  <li>
                    <i className="icon-mail"></i>
                    <span>
                      <Link to="">{partnerDetail.contact_email}</Link>
                    </span>
                  </li>
                  <li>
                    <i className="icon-earth"></i>
                    <span>
                      <Link
                        to={
                          partnerDetail.shop_site_url
                            ? partnerDetail.shop_site_url
                            : ""
                        }
                        target="_blank"
                      >
                        {partnerDetail.shop_site_url}
                      </Link>
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row other-shops">
            <div className="col-sm-12">
              <div className="inner">
                <div className="partner-center-content">
                  <h3>Eine anderen Partner wählen</h3>
                  <select
                    className="form-control"
                    value={selected_partner_id}
                    onChange={this.handlePartnerChange}
                  >
                    <option value="0">Partner auswählen</option>
                    {partnerList.length === 0
                      ? null
                      : partnerList.map((partner) => (
                          <option key={partner.id} value={partner.id}>
                            {partner.shop_name}
                          </option>
                        ))}
                  </select>
                </div>
              </div>
            </div>
          </div>

          <section className="container">
            <div className="row">
              <div className="col-sm-12">
                {prevPartnerId && (
                  <Link
                    className="partner-link previous-partner"
                    to={`/partner-detail?id=${prevPartnerId}`}
                    // onClick={(e) => {
                    //   e.preventDefault();
                    //   const { viewed_partner_list } = this.state;
                    //   if (viewed_partner_list.length > 0) {
                    //     const previous_partner_id = viewed_partner_list.pop();
                    //     this.props.history.push(
                    //       `/partner-detail?id=${previous_partner_id}`
                    //     );
                    //     this.props.fetchPartnerDetail(previous_partner_id);
                    //     this.setState({
                    //       viewed_partner_list: viewed_partner_list,
                    //     });
                    //   } else {
                    //     this.props.alert.show("Keine Voheriger Partner.");
                    //   }
                    // }}
                  >
                    <i className="icon-left-open"></i>
                    <span>Vorheriger Partner</span>
                  </Link>
                )}
                {nextPartnerId && (
                  <Link
                    className="partner-link next-partner"
                    to={`/partner-detail?id=${nextPartnerId}`}
                    // onClick={(e) => {
                    //   e.preventDefault();
                    //   if (selected_partner_id !== "0") {
                    //     this.props.history.push(
                    //       `/partner-detail?id=${selected_partner_id}`
                    //     );
                    //     this.props.fetchPartnerDetail(selected_partner_id);
                    //     this.setState({
                    //       viewed_partner_list: [
                    //         ...this.state.viewed_partner_list,
                    //         this.state.current_partner_id,
                    //       ],
                    //     });
                    //   } else {
                    //     this.props.alert.show("Auswählen Partner Bitte.");
                    //   }
                    // }}
                  >
                    <span>Nächester Partner</span>
                    <i className="icon-right-open"></i>
                  </Link>
                )}
              </div>
            </div>
          </section>
        </div>
      </div>
    ) : (
      <NotFound404 />
    );
  }
}

PartnerDetail = connect(
  (state) => ({
    partnerDetail: state.partnerDetail,
    partnerList: state.partnerList,
  }),
  {
    fetchPartnerList,
    fetchPartnerDetail,
  }
)(PartnerDetail);

export default withLayout(withAlert()(PartnerDetail));
