import httpClient, {
  authHttpClient,
  serverErrorToErrorList,
} from "../../services/httpClient";
import { SET_SERVER_ERROR } from "../actionTypes";

export const placeGuestUserOrder = (data) => async (dispatch) => {
  try {
    let response = await httpClient.post(`/guest-user-orders`, data);

    return response.data.data;
  } catch (e) {
    dispatch({
      type: SET_SERVER_ERROR,
      payload: { errors: serverErrorToErrorList(e) },
    });
    throw e;
  }
};

export const placeOrder = (data) => async (dispatch) => {
  try {
    let response = await authHttpClient.post(`/orders`, data);

    return response.data.data;
  } catch (e) {
    dispatch({
      type: SET_SERVER_ERROR,
      payload: { errors: serverErrorToErrorList(e) },
    });
    throw e;
  }
};

export const getStripePaymentIntent = (order) => async (dispatch) => {
  try {
    let response = await httpClient.post(
      `/payments/stripe/payment-intent`,
      order
    );

    return response.data.data;
  } catch (e) {
    dispatch({
      type: SET_SERVER_ERROR,
      payload: { errors: serverErrorToErrorList(e) },
    });
    throw e;
  }
};
