import axios from "axios";

const config = {
  baseURL: process.env.REACT_APP_API_URL,
  timeout: process.env.REACT_APP_TIMEOUT,
};

export default axios.create({
  ...config,
  transformRequest: [
    function (data, headers) {
      headers.Accept = "application/json";
      headers["Content-Type"] = "application/json";

      return JSON.stringify(data);
    },
  ],
});

export const authHttpClient = axios.create({
  ...config,
  transformRequest: [
    function (data, headers) {
      headers.Accept = "application/json";
      headers["Content-Type"] = "application/json";
      headers.Authorization = `Bearer ${localStorage.getItem("token")}`;
      if (headers["Content-Type"] === "multipart/form-data") {
        return data;
      } else {
        return JSON.stringify(data);
      }
    },
  ],
});
export const authMultipartFormDataHttpClient = axios.create({
  ...config,
  transformRequest: [
    function (data, headers) {
      headers.Accept = "application/json";
      headers["Content-Type"] = "multipart/form-data";
      headers.Authorization = `Bearer ${localStorage.getItem("token")}`;

      const formData = new FormData();
      for (let key in data) {
        formData.append(key, data[key]);
      }

      return formData;
    },
  ],
});

export const filterQueryParams = (params) => {
  let nonEmptyParams = {};
  for (let i in params) {
    if (params[i] !== "") {
      nonEmptyParams[i] = params[i];
    }
  }

  return nonEmptyParams;
};

export const serverErrorToErrorList = (e) => {
  if (typeof e.response === "undefined") {
    return [e.message];
  }

  const response = e.response.data;
  if (typeof response.errors !== "undefined") {
    let errors = [];
    for (let key in response.errors) {
      errors = errors.concat(response.errors[key]);
    }
    return errors;
  } else {
    return [response.message];
  }
};
