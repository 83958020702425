import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { fetchPartnerList } from "../../redux/actions/partnerActions";
import queryString from "query-string";
import { placeOrder } from "../../redux/actions/orderActions";
import { Field, reduxForm } from "redux-form";
import { inputField } from "../redux_form_fields/inputField";
import { requiredAndEmail } from "../../services/validate";
import ServerError from "../common/ServerError";
import withLayout from "../common/withLayout";

class Coupon extends Component {
  state = {
    selectedPartner: { owner_id: 0, shop_name: "" },
    numberOfVouchers: "1",
    voucherAmount: "10",
    totalAmount: 10,
  };

  async componentDidMount() {
    await this.props.fetchPartnerList({ all: true });
    const { owner_id } = queryString.parse(this.props.location.search);
    const selectedPartner = owner_id
      ? this.props.partnerList.find(
          (partner) => parseInt(partner.owner_id) === parseInt(owner_id)
        )
      : this.props.partnerList[0];
    this.setState({
      selectedPartner: selectedPartner || { owner_id: 0, shop_name: "" },
    });
  }

  onSubmit = (formProps) => {
    const { selectedPartner, voucherAmount, numberOfVouchers, totalAmount } =
      this.state;

    const order = {
      partner: selectedPartner,
      partner_id: selectedPartner.owner_id,
      items: [
        {
          coupon_value: voucherAmount,
          quantity: numberOfVouchers,
          total: totalAmount,
        },
      ],
      total: totalAmount,
      payment_method: "paypal",
    };

    if (!this.props.authentication) {
      order.guest = formProps;
    }

    this.props.history.push({
      pathname: `/checkout`,
      state: order,
    });
  };

  render() {
    const { partnerList, handleSubmit } = this.props;
    const { selectedPartner } = this.state;

    return (
      <div>
        <div className="coupon-title-wrap">
          <div className="container">
            <h2>Coupon kaufen</h2>
          </div>
          <i className="icon-coupon" />
        </div>

        <section className="container coupon-wrap">
          <div className="row">
            <div className="col-sm-4">
              <div className="form-group">
                <label>Shop</label>
                <select
                  className="form-control"
                  value={selectedPartner.owner_id}
                  onChange={(e) => {
                    e.preventDefault();
                    this.setState({
                      selectedPartner: partnerList.find(
                        (partner) =>
                          parseInt(partner.owner_id) ===
                          parseInt(e.target.value)
                      ),
                    });
                  }}
                >
                  {partnerList.map((partner) => (
                    <option value={partner.owner_id} key={partner.owner_id}>
                      {partner.shop_name} €
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="form-group">
                <label>Anzahl der Coupons</label>
                <select
                  className="form-control"
                  value={this.state.numberOfVouchers}
                  onChange={(e) => {
                    e.preventDefault();
                    this.setState({
                      numberOfVouchers: e.target.value,
                      totalAmount: e.target.value * this.state.voucherAmount,
                    });
                  }}
                >
                  {[1, 2, 3, 4, 5].map((number) => (
                    <option value={number} key={number}>
                      {number} X Coupon
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="form-group">
                <label>Coupon Betrag</label>
                <select
                  className="form-control"
                  value={this.state.voucherAmount}
                  onChange={(e) => {
                    e.preventDefault();
                    this.setState({
                      voucherAmount: e.target.value,
                      totalAmount: e.target.value * this.state.numberOfVouchers,
                    });
                  }}
                >
                  {[10, 15, 20, 50, 100, 150, 200].map((value) => (
                    <option value={value} key={value}>
                      {value} Euro
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <h5>Ihre Coupon-Auswahl</h5>

              <table className="table table-borderless">
                <tbody>
                  <tr>
                    <td>{this.state.numberOfVouchers} X Coupon</td>
                    <td>{this.state.voucherAmount} Euro</td>
                  </tr>
                  <tr className="total-coupon-amount">
                    <td>
                      <b>Gesamt</b>
                    </td>
                    <td>
                      <b>{this.state.totalAmount} Euro</b>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <form onSubmit={handleSubmit(this.onSubmit)}>
            <div className="row">
              {!this.props.authentication ? (
                <Fragment>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Vorname</label>
                      <Field
                        className="form-control"
                        type="text"
                        component={inputField}
                        name="first_name"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Familienname</label>
                      <Field
                        className="form-control"
                        type="text"
                        component={inputField}
                        name="last_name"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>E-Mail-Adresse</label>
                      <Field
                        className="form-control"
                        type="text"
                        component={inputField}
                        name="email"
                        validate={requiredAndEmail}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Handynummer</label>
                      <Field
                        className="form-control"
                        type="text"
                        component={inputField}
                        name="phone_no"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label> Stadt </label>
                      <Field
                        className="form-control"
                        type="text"
                        component={inputField}
                        name="city"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Straße Adresse</label>
                      <Field
                        className="form-control"
                        type="text"
                        component={inputField}
                        name="street_address"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Hausnummer</label>
                      <Field
                        className="form-control"
                        type="text"
                        component={inputField}
                        name="house_no"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Postleitzahl</label>
                      <Field
                        className="form-control"
                        type="text"
                        component={inputField}
                        name="postal_code"
                      />
                    </div>
                  </div>
                </Fragment>
              ) : null}
            </div>
            <div className="row coupon-btn-wrap">
              <div className="col-12">
                <ServerError />
              </div>
              <div className="col-12">
                <div className="form-group">
                  <input
                    type="submit"
                    className="btn btn-primary"
                    value="Zur Kasse gehen"
                  />
                </div>
              </div>
            </div>
          </form>
        </section>
      </div>
    );
  }
}

Coupon = reduxForm({
  form: "coupon",
})(Coupon);

Coupon = connect(
  (state) => ({
    partnerList: state.partnerList,
    authentication: state.authentication,
  }),
  {
    fetchPartnerList,
    placeOrder,
  }
)(Coupon);

export default withLayout(Coupon);
