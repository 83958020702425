import React from "react";
import withLayout from "../common/withLayout";

const LISTINFO_TELEPHONE_NUMBER = "+49(0)4651 889 8570";
const LISTINFO_DATA_PROTECTION_EMAIL = "Datenschutz@list-info.de";
const PAYPAL_PRIVACY_LINK =
  "https://www.paypal.com/de/webapps/mpp/ua/privacy-full";
const MATOMO_PRIVACY_POLICY_LINK = "https://matomo.org/privacy-policy";
const VIMEO_PRIVACY_POLICY_LINK = "https://vimeo.com/privacy";
const US_PRIVACY_SHIELD_VIMEO_LINK =
  "https://www.privacyshield.gov/participant?id=a2zt00000008V77AAE&status=Active";
const US_PRIVACY_SHIELD_META_LINK =
  "https://www.privacyshield.gov/participant?id=a2zt0000000GnywAAC&status=Active";
const EDGE_COOKIE_LINK =
  "https://support.microsoft.com/de-de/help/4027947/microsoft-edge-delete-cookies";
const FIREFOX_COOKIE_LINK =
  "https://support.mozilla.org/de/kb/cookies-erlauben-und-ablehnen";
const CHROME_COOKIE_LINK =
  "http://support.google.com/chrome/bin/answer.py?hl=de&hlrm=en&answer=95647";
const SAFARI_COOKIE_LINK =
  "https://support.apple.com/de-de/guide/safari/sfri11471/12.0/mac/10.14";
const OPERA_COOKIE_LINK =
  "https://help.opera.com/de/latest/web-preferences/#cookies";
const LISTINFO_INSTAGRAM_LINK = "https://www.instagram.com/listinfo.de";
const INSTAGRAM_HELP_LINK = "https://help.instagram.com/519522125107875";
const INSTAGRAM_LOGIN_TO_PRIVACY_LINK =
  "https://www.instagram.com/accounts/login/?next=/accounts/privacy_and_security";
const FACEBOOK_POLICY_COOKIE_LINK = "https://www.facebook.com/policies/cookies";
const FACEBOOK_SETTINGS_ADS_LINK = "https://www.facebook.com/settings?tab=ads";
const FACEBOOK_SETTINGS_LINK = "https://www.facebook.com/settings";
const FACEBOOK_ADS_PREFERENCE_LINK = "https://www.facebook.com/ads/preferences";
const FACEBOOK_HELP_CONTACT_LINK =
  "https://www.facebook.com/help/contact/367438723733209";
const PINTEREST_LIGHTHOUSE_LINK =
  "https://www.pinterest.de/pin/897764506938182903";
const PINTEREST_ADS_LINK = "https://ads.pinterest.com";
const PINTEREST_ANALYTICS_LINK = "https://analytics.pinterest.com";
const PINTEREST_PRIVACY_POLICY_LINK =
  "https://policy.pinterest.com/de/privacy-policy";
const PINTEREST_PRIVACY_SETTINGS_LINK =
  "https://www.pinterest.de/settings/privacy";
const OPEN_STREET_PRIVACY_POLICY_LINK =
  "https://wiki.osmfoundation.org/wiki/Privacy_Policy";
const OPEN_STREET_FOUNDATION_POLICY_LINK =
  "https://operations.osmfoundation.org/policies";
const OPEN_STREET_TERMS_TO_USE_LINK =
  "https://wiki.osmfoundation.org/wiki/Terms_of_Use";
const BFDI_LINK =
  "https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html";

const ListinfoDataProtectionMailLink = () => {
  return (
    <a href={`mailto:${LISTINFO_DATA_PROTECTION_EMAIL}`}>
      {LISTINFO_DATA_PROTECTION_EMAIL}
    </a>
  );
};

const OpenInNewTab = (props) => {
  return (
    <a href={props.link} target="_blank" rel="noopener noreferrer">
      {props.link}
    </a>
  );
};

const DataProtection = () => {
  return (
    <section className="data-protection-page">
      <div className="container">
        <h4>Datenschutz</h4>
        <div className="row">
          <div className="col-md-12">
            <p>
              Wir freuen uns über Ihr Interesse an unserer Website&nbsp;
              <a
                href="https://list-marketplace.de"
                target="_blank"
                rel="noopener noreferrer"
              >
                www.list-marketplace.de
              </a>
              &nbsp;(„Website“). Diese Website wird von der
              Appartementvermittlung Familie Clausen GmbH (im folgenden
              „Appartementvermittlung Familie Clausen“ oder „wir“) betrieben und
              bietet Ihnen als Nutzer (im folgenden „Nutzer“ oder „Sie“) die
              Möglichkeit, sich über die durch uns vermittelten Feriendomizile
              auf Sylt, Preise und Reisezeiträume zu informieren und ein
              Feriendomizil zu buchen. Des Weiteren können Sie uns Anfragen über
              unser Kontaktformular zu senden und den Status Ihrer Buchung
              einsehen und unsere zusätzlichen Service-Leistungen wie den
              Einkaufs-, Feinkost-, Wäsche- oder unseren Strandkorbservice oder
              unsere Angebote um das Housekeeping buchen, um Ihren Urlaub so
              erholsam wie möglich zu gestalten.
            </p>
            <p>
              Im Folgenden informieren wir Sie darüber, welche Daten wir erheben
              und wofür wir sie nutzen, sofern Sie unsere Website besuchen oder
              eine Anfrage über unser Kontaktformular an uns senden bzw. ein
              Feriendomizil, die Anreise oder Serviceleistungen buchen. Die
              vorliegende Datenschutzerklärung erläutert, auf welcher
              rechtlichen Grundlage und zu welchem Zweck dies geschieht.
              Überdies informieren wir Sie darüber, welche Rechte Ihnen im
              Hinblick auf die Nutzung der personenbezogenen Daten zustehen.
              Sollten Sie Fragen bezüglich der Verwendung Ihrer persönlichen
              Daten durch uns haben, wenden Sie sich bitte an uns als
              verantwortliche Stelle (Kontakt unter Ziffer 1).
            </p>
          </div>

          <div className="col-md-12">
            <h5>Verantwortlichen Stelle</h5>
            <p>
              Betreiber dieser Website und damit verantwortliche Stelle für die
              Datenverarbeitung ist:
              <br />
              Appartementvermittlung Familie Clausen GmbH
              <br />
              Am Buttgraben 6a 25992 List
              <br />
              Tel:&nbsp;
              <a href={`tel:${LISTINFO_TELEPHONE_NUMBER}`}>
                {LISTINFO_TELEPHONE_NUMBER}
              </a>
              <br />
              Email:&nbsp;
              <ListinfoDataProtectionMailLink />
            </p>
          </div>
          <div className="col-md-12">
            <h5>Widerruf Ihrer Einwilligung zur Datenverarbeitung</h5>
            <p>
              Manche Datenverarbeitungsvorgänge sind nur mit Ihrer
              ausdrücklichen Einwilligung möglich. Sie können eine etwaig
              bereits erteilte Einwilligung jederzeit mit Wirkung für die
              Zukunft widerrufen. Dazu reicht eine formlose Mitteilung per
              E-Mail an uns (Kontakt unter Ziffer 1). Die Rechtmäßigkeit der bis
              zum Widerruf erfolgten Datenverarbeitung bleibt vom Widerruf
              unberührt.
            </p>
          </div>
          <div className="col-md-12">
            <h5>Allgemeines zur Datenverarbeitung über unsere Website</h5>
            <p>
              Wenn Sie diese Website benutzen, werden verschiedene
              personenbezogene Daten erhoben.
            </p>
            <p>
              „Personenbezogene Daten“ sind solche, mit denen Sie direkt oder
              indirekt identifiziert werden können (wie etwa Name, Adresse,
              E-Mail-Adresse, Telefonnummer, IP-Adresse).
            </p>
            <p>
              Die Erhebung und Verarbeitung personenbezogener Daten erfolgt
              grundsätzlich nur, soweit dies von einer gesetzlichen
              Erlaubnisnorm gestattet ist oder Sie in die jeweilige Nutzung
              eingewilligt haben. Sobald der Zweck der Datenverarbeitung
              weggefallen ist, werden die Daten gelöscht, es sei denn Sie haben
              einer weiteren Nutzung zugestimmt oder der Löschung stehen
              gesetzliche Aufbewahrungspflichten entgegen. Im Einzelnen werden
              die folgenden Datenverarbeitungsvorgänge erfasst:
            </p>
          </div>
          <div className="col-md-12">
            <h5>Datenerfassung auf unserer Website</h5>
            <div>
              <h6 className="sub-heading">
                Beschreibung der Datenverarbeitung
              </h6>
              <p>
                Nutzer können unsere Website besuchen, ohne sich zu
                registrieren. Beim Besuch der Website und bei jedem Abruf einer
                Datei werden aber Zugriffsdaten über diesen Vorgang in einer
                Protokolldatei durch unser IT-System erfasst.
                Appartementvermittlung Familie Clausen bzw. der
                Webspace-Provider erfasst diese Informationen in so genannten
                Server-Log-Dateien, die Ihr Browser automatisch an uns
                übermittelt. Dies sind:
              </p>
              <ul className="list-group">
                <li>Referrer URL (zuvor besuchte Webseite)</li>
                <li>Angeforderte Webseite oder Datei</li>
                <li>Browsertyp und Browserversion</li>
                <li>verwendetes Betriebssystem</li>
                <li>Verwendeter Gerätetyp</li>
                <li>Uhrzeit des Zugriffs</li>
                <li>IP-Adresse, gekürzt</li>
              </ul>
              <p>
                Die Erfassung dieser Daten erfolgt automatisch, sobald Sie
                unsere Website betreten. Eine Zusammenführung dieser Daten mit
                anderen Datenquellen wird nicht vorgenommen
              </p>
            </div>
            <div>
              <h6 className="sub-heading">Rechtsgrundlage und Zweck</h6>
              <p>
                Grundlage für die Datenverarbeitung ist Art. 6 Abs. 1 lit. f
                DSGVO, der die Verarbeitung bei berechtigtem Interesse erlaubt,
                sofern dem keine überwiegenden Interessen, Grundrechte und
                Grundfreiheiten des Nutzers entgegenstehen. Die vorübergehende
                Speicherung dieser Daten ist notwendig, um die Inhalte der
                Website für den Nutzer darzustellen. Daher ist die Erfassung
                dieser Informationen für eine Darstellung unseres Webangebots
                erforderlich. Hierfür muss auch die IP-Adresse des Nutzers für
                die Dauer der Sitzung gespeichert bleiben. Die Speicherung in
                Logfiles erfolgt, um die Funktionsfähigkeit der Website
                sicherzustellen und diese zu optimieren, um unsere Dienste zu
                verbessern. Zudem soll hierdurch die Sicherheit unserer
                IT-Systeme gewährleistet werden. In diesen Zwecken liegt das
                berechtigte Interesse von uns an der Datenverarbeitung nach Art.
                6 Abs. 1 lit. f DSGVO.
              </p>
            </div>
            <div>
              <h6 className="sub-heading">Dauer der Speicherung</h6>
              <p>
                Diese Daten werden gelöscht, wenn eine weitere Aufbewahrung
                nicht mehr für den Zweck der Speicherung erforderlich ist. Für
                die Bereitstellung der Website ist dies mit Beendigung der
                jeweiligen Sitzung der Fall. Eine darüberhinausgehende
                Speicherung ist möglich, wenn die IP-Adressen der Nutzer
                gelöscht bzw. gekürzt werden, sodass eine Zuordnung des
                aufrufenden Nutzers nicht möglich ist.
              </p>
            </div>
          </div>
          <div className="col-md-12">
            <h5>
              Anfragen per Kontaktformular, E-Mail, Telefon und Chat-Funktion
            </h5>
            <div>
              <h6 className="sub-heading">
                Beschreibung der Datenverarbeitung
              </h6>
              <p>
                Wenn Sie uns über unser Rückruf- oder Kontaktformular Anfragen
                zukommen lassen, werden Ihre Angaben aus dem Kontaktformular
                inklusive der von Ihnen dort angegebenen Kontaktdaten zwecks
                Bearbeitung der Anfrage und für den Fall von Anschlussfragen bei
                uns gespeichert. Diese Daten geben wir nicht ohne Ihre
                Einwilligung weiter.
              </p>
              <p>
                Hierfür werden über die Eingabemaske folgende Daten an uns
                übermittelt und gespeichert: Email-Adresse und Name sowie ggfs.
                Telefonnummer und freiwilliger Basis postalische Anschrift
                etwaige Angaben zu personenbezogenen Daten, die Sie im
                Anfragentext machen.
              </p>
              <p>
                Für die Verarbeitung dieser Daten wird im Rahmen des
                Absende-Vorganges die Einwilligung des Nutzers eingeholt und auf
                diese Datenschutzerklärung verwiesen.
              </p>
              <p>
                Alternativ wird auf der Website für die Kontaktaufnahme eine
                E-Mail-Adresse und Telefonnummer bereitgestellt. In diesem Fall
                werden die per Email oder telefonisch übermittelten
                personenbezogenen Daten des Nutzers gespeichert.
              </p>
              <p>
                Zudem besteht die Möglichkeit uns über die Live-Chat-Funktion
                auf der Website zu kontaktieren. In diesem Fall speichern wir
                Ihre personenbezogenen Daten, sowie die Angaben die Sie uns im
                Online-Chat mitteilen. Die Bereitstellung erfolgt freiwillig
              </p>
              <p>
                Sie können den Chat wie ein Kontaktformular nutzen, um nahezu in
                Echtzeit mit unseren Mitarbeitern zu chatten. Beim Start des
                Chats fallen folgende personenbezogene Daten an:
              </p>
              <ul className="list-group">
                <li>Datum und Uhrzeit des Aufrufs,</li>
                <li>Browsertyp/ -version</li>
                <li>IP-Adresse,</li>
                <li>verwendetes Betriebssystem,</li>
                <li>URL der zuvor besuchten Webseite,</li>
              </ul>
              <p>
                Menge der gesendeten Daten.Ihre Anfrage erhält unser interner
                Kundenservice. Alle unsere Mitarbeiter wurden und werden zum
                Thema des Datenschutzes geschult und zum sicheren und
                vertrauensvollen Umgang mit Kundendaten verpflichtet. Ihre
                Anfragen übermitteln wir nicht in Drittländer oder an
                Organisationen außerhalb der EU.
              </p>
            </div>
            <div>
              <h6 className="sub-heading">
                Rechtsgrundlage und Zweck der Verarbeitung
              </h6>
              <p>
                Die Daten verarbeiten wir ausschließlich zum Zweck der
                Beantwortung Ihres Anliegens bzw. zur gewünschten
                Kontaktaufnahme mit Ihnen und die damit verbundene technische
                Verwaltung. Die Verarbeitung der zur Kontaktaufnahme
                eingegebenen Daten erfolgt auf Grundlage Ihrer Einwilligung nach
                Art. 6 Abs. 1 lit. a DSGVO. Sofern im Falle der Kontaktaufnahme
                durch Telefon oder im Rahmen der Chatfunktion in dem Anruf des
                Nutzers keine Einwilligung in die Datenverarbeitung und
                Speicherung gesehen werden sollte, ist diese nach Art. 6 Abs. 1
                lit. b DSGVO gerechtfertigt, weil wir die vorstehend genannten
                Daten zur Bearbeitung der Anfrage benötigen. Die Kontaktaufnahme
                zu uns ist stets freiwillig. Im Falle einer Nichtbereitstellung
                der Daten können wir Ihr Anliegen nicht bearbeiten.
              </p>
            </div>
            <div>
              <h6 className="sub-heading">Dauer der Speicherung</h6>
              <p>
                Nach Bearbeitung Ihrer Anfrage löschen wir die Daten zu Ihrer
                Kontaktaufnahme unverzüglich, spätestens jedoch sieben Tage nach
                Erledigung der Anfrage. Dieser Speicherdauer können gesetzliche
                Aufbewahrungsfristen oder eine längere Aufbewahrungsfrist zu
                Beweis- und Schulungszwecken entgegenstehen. So speichern wir
                diese Daten zu Beweiszwecken, zu etwaigen Geltendmachung,
                Ausübung oder Verteidigung von Rechtsansprüchen außerdem darüber
                hinaus noch für einem Übergangszeitraum von drei Jahren, in dem
                Sie uns die Daten mitgeteilt haben und im Falle von etwaigen
                Rechtsstreitigkeiten bis zu deren Beendigung.
              </p>
              <p>
                Wenn Ihre Anfrage in Zusammenhang mit einem Vertrag steht,
                speichern wir Ihre Anfrage zum Zwecke der Erfüllung der
                gesetzlichen Aufbewahrungspflichten (Art. 6 Abs. 1 lit. c
                DSGVO). Wir löschen Ihre Daten in diesem Fall spätestens mit
                Ablauf der gesetzlichen Aufbewahrungsfrist (§ 147 Abs. 3 AO), d.
                h. nach Ablauf von 6 oder 10 Jahren, beginnend mit dem
                Vertragsabschluss. Mit Ablauf dieser Aufbewahrungsfrist werden
                wir diese Daten unverzüglich löschen, ohne dass Sie uns dazu
                auffordern müssen.
              </p>
            </div>
          </div>
          {/* 6 */}
          <div className="col-md-12">
            <h5>Datenverarbeitung zur Buchung und Vertragsabwicklung</h5>
            <div>
              <h6 className="sub-heading">
                Beschreibung der Datenverarbeitung
              </h6>
              <p>
                Nutzer können über die Website Feriendomizile, die Anreise und
                zusätzliche Serviceleistungen wie den Einkaufs-, Feinkost-,
                Wäsche- und unseren Strandkorbservice oder unsere Angebote um
                das Housekeeping buchen. Im Rahmen des Buchungsprozesses werden
                personenbezogene Daten erhoben und gespeichert. Hierfür müssen
                Sie folgende Angaben machen: Name, Adressdaten, E-Mail-Adresse,
                Telefon.
              </p>
              <p>
                Überdies erheben und speichern wir Daten, die Nutzer uns im
                Rahmen des Bezahlvorgangs mitteilen. Nutzern stehen verschieden
                Zahlungsmittel zur Verfügung. Wir akzeptieren als Zahlungsmittel
                Paypal, Kreditkarte und auch eine Überweisung. Je nachdem,
                welchen Zahlungsdienst Sie wählen, werden Sie zur sicheren
                Zahlung auf die Seite desjeweiligen Drittanbieters
                weitergeleitet. Im Falle einer Überweisung können Sie das
                Feriendomizil und die anderen gebuchten Serviceleistungen
                zunächst nur anfragen. Eine Buchung erfolgt erst nach
                Zahlungseingang.
              </p>
              <p>
                Zur Abwicklung der Buchung werden die Daten über eine
                Schnittstelle in unsere Buchungssoftware übertragen. In dieser
                werden neben den personenbezogenen Daten auch Informationen zu
                der Buchung, wie dem gebuchten Feriendomizil, Zusatzleistungen
                und Zahlungsmodalitäten verarbeitet. Nach der Buchung erhalten
                Nutzer eine Bestätigungsmail zu Ihrer Buchung mit einem Link zu
                dem Buchungssystem und darüber hinaus gesondert per Mail, Post
                oder Telefon einen Zugangscode. Nutzer können dadurch über die
                Buchungssoftware selbst die Details zu Ihrer Buchung und den
                gespeicherten Daten einsehen. Sie können überdies
                Zusatzleistungen buchen und über diese Buchungssoftware die
                Zahlungen veranlassen.
              </p>
              <p>
                Die betroffenen personenbezogenen Daten werden vertraulich
                behandelt und nicht an Dritte weitergegeben, es sei denn, dies
                ist zur vertraglichen Abwicklung etwaiger Buchungen erforderlich
                oder die Nutzer haben in die Datenverarbeitung eingewilligt oder
                wir sind hierzu gesetzlich verpflichtet (dazu unter 7).
              </p>
            </div>
            <div>
              <h6 className="sub-heading">
                Zweck und Rechtsgrundlage für die Datenverarbeitung
              </h6>
              <p>Die Verarbeitung der personenbezogenen Daten für</p>
              <p>
                die Buchung eines Feriendomizils und auch bezüglich zusätzlicher
                Serviceleistungen erfolgt für die Vorbereitung, den Abschluss
                und die Abwicklung des Vertrags. Rechtsgrundlage für diese
                Erhebung, Speicherung, Verarbeitung und Verwendung ist Art. 6
                Abs. 1 lit. b DSGVO.
              </p>
            </div>
            <div>
              <h6 className="sub-heading">
                Dauer der Speicherung; Widerspruchsmöglichkeit
              </h6>
              <p>
                Der Zugangslink zu der Buchungssoftware steht dem Nutzer bis zu
                4 Wochen nach Abreise zur Verfügung. Danach steht dem Nutzer der
                Zugang nicht mehr zur Verfügung.
              </p>
              <p>
                sofern der Nutzer eine E-Mail an&nbsp;
                <ListinfoDataProtectionMailLink />
                &nbsp;unter Angabe seines Kundenkontos verbunden mit der Bitte
                um Löschung sendet.
              </p>
              <p>
                Im Falle der Buchung eines Feriendomizils und zusätzlicher
                Serviceleistungen werden die personenbezogenen Daten und Angaben
                zu der Buchung für die Dauer der Vertragsabwicklung gespeichert.
                Danach werden alle Angaben zu Buchungen, gelöscht, sofern dem
                nicht gesetzliche Aufbewahrungspflichten entgegenstehen. Nutzer
                können Ihre Einwilligung darin erteilen, dass Ihnen Daten über
                die Dauer der Vertragsabwicklung hinaus, maximal aber bis zu 10
                Jahre für zurückliegende Bestellungen gespeichert und
                verarbeitet werden dürfen.
              </p>
              <p>
                Appartementvermittlung Familie Clausen löscht die Daten
                spätestens mit Ablauf der gesetzlichen Aufbewahrungsfrist (§ 147
                Abs. 3 Abgabenordnung), d. h. nach Ablauf von 6 bzw. 10 Jahren,
                beginnend mit dem Vertragsabschluss, ohne dass der Nutzer dazu
                auffordern muss. Darüber hinaus löschen wir personenbezogene
                Daten auf Wunsch des Nutzers, sofern dem keine gesetzlichen
                Aufbewahrungspflichten entgegenstehen.
              </p>
            </div>
          </div>
          {/* 7 */}
          <div className="col-md-12">
            <h5>
              Weitergabe personenbezogener Daten an Dienstleister zur
              Buchungsabwicklung, Bewertungen unserer Feriendomizile und
              Leistungen, Zweck und Rechtsgrundlage, Widerspruchsmöglichkeit
            </h5>
            <div>
              <p className="sub-heading">
                Die betroffenen personenbezogenen Daten werden von uns
                vertraulich behandelt und grundsätzlich nicht an Dritte
                weitergegeben, es sei denn, dies ist zur Abwicklung einer
                Buchung erforderlich oder Sie haben in die Datenverarbeitung
                eingewilligt oder Appartementvermittlung Familie Clausen ist
                hierzu gesetzlich verpflichtet.
              </p>
            </div>
            <div>
              <p className="sub-heading">
                Zur Abwicklung der Buchung arbeiten wir mit externen
                Dienstleistern zusammen, die uns ganz oder teilweise bei der
                Durchführung geschlossener Verträge (Bereitstellung der
                Buchungssoftware und Buchung von unseren zusätzlichen
                Serviceleistungen) unterstützen:
              </p>
              <p>
                In Bezug auf die Abwicklung von Buchungen arbeiten wir mit der
                Buchungssoftware unseres Dienstleisters vOffice GmbH, Dünenweg
                1H, 23730 Neustadt. vOffice unterstützt uns bei der Wartung und
                Fehlerbehebung dieser Buchungssoftware und ist über einen
                Auftragsverarbeitungsvertrag zum vertraulichen Umgang mit Ihren
                Daten verpflichtet. Eine etwaige Datenverarbeitung erfolgt
                allein im Rahmen einer Fehlerbehebung oder Wartung der Software
                auf Basis von Art. 6 Abs. 1 lit. b DSGVO.
              </p>
              <p>
                Wir geben Ihre personenbezogenen Daten an den Eigentümer des
                Feriendomizils weiter, für den wir den Mietvertrag über das
                Feriendomizil vermittelt haben.
              </p>
              <p>
                Für die Erbringung der gebuchten zusätzlichen Serviceleistungen
                wie Wäschelieferung, Feinkostlieferung etc. werden wir von
                Dienstleistern unterstützt, an die wir unter Umständen
                personenbezogene Daten nur zum Zwecke der Erfüllung der
                vertraglichen Pflichten übermittelt.
              </p>
              <p>
                Diese Datenweitergabe erfolgt auf Basis von Art. 6 Abs. 1 lit. b
                DSGVO. Jeder Dienstleister erhält nur diejenigen Daten, die für
                die Erbringung der jeweiligen Leistung erforderlich sind. Diese
                Dienstleister sind zum vertraulichen Umgang mit Ihren Daten
                verpflichtet. Die Weitergabe der Daten ist zur Abwicklung und
                Erfüllung der gebuchten Serviceleistung erforderlich.
              </p>
            </div>
            <div>
              <p className="sub-heading">
                Zur Abwicklung der Zahlung der Buchungen werden unter Umständen
              </p>
              <p>
                Zahlungsdienstleister eingesetzt. Eine Datenübermittlung erfolgt
                dabei je nach Wahl des Zahlungsmittels an folgende
                Dienstleister:
              </p>
              <h6>Paypal</h6>
              <p>
                Bei Auswahl der Zahlung via PayPal, Kreditkarte via PayPal,
                Lastschrift via PayPal oder – falls von uns angeboten - "Kauf
                auf Rechnung" oder "Ratenzahlung" via PayPal geben wir Ihre
                Bestelldaten zum Zwecke der Zahlungsabwicklung an die PayPal
                (Europe) S.a.r.l. et Cie, S.C.A., 22-24 Boulevard Royal, L-2449
                Luxembourg (nachfolgend "PayPal"), weiter.
              </p>
              <p>
                PayPal behält sich für die Zahlungsmethoden Kreditkarte via
                PayPal, Lastschrift via PayPal oder falls angeboten - "Kauf auf
                Rechnung" oder "Ratenzahlung" via PayPal die Durchführung einer
                Bonitätsauskunft vor. Hierfür werden Ihre Zahlungsdaten
                gegebenenfalls gemäß Art. 6 Absatz 1 Buchstabe f DSGVO auf Basis
                des berechtigten Interesses von PayPal an der Feststellung Ihrer
                Zahlungsfähigkeit an Auskunfteien weitergegeben. Das Ergebnis
                der Bonitätsprüfung in Bezug auf die statistische
                Zahlungsausfallwahrscheinlichkeit verwendet PayPal zum Zwecke
                der Entscheidung über die Bereitstellung der jeweiligen
                Zahlungsmethode. Die Bonitätsauskunft kann
                Wahrscheinlichkeitswerte enthalten (sog. Score-Werte). Soweit
                Score-Werte in das Ergebnis der Bonitätsauskunft einfließen,
                haben diese ihre Grundlage in einem wissenschaftlich anerkannten
                mathematisch-statistischen Verfahren. In die Berechnung der
                Score-Werte fließen unter anderem, aber nicht ausschließlich,
                Anschriftendaten ein. Weitere datenschutzrechtliche
                Informationen, unter anderem zu den verwendeten Auskunfteien,
                entnehmen Sie bitte der Datenschutzerklärung von PayPal:&nbsp;
                <a
                  href={PAYPAL_PRIVACY_LINK}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {PAYPAL_PRIVACY_LINK}
                </a>
              </p>
              <p>
                Sie können dieser Verarbeitung Ihrer Daten jederzeit durch eine
                Nachricht an PayPal widersprechen. Jedoch bleibt PayPal ggf.
                weiterhin berechtigt, Ihre personenbezogenen Daten zu
                verarbeiten, sofern dies zur vertragsgemäßen Zahlungsabwicklung
                erforderlich ist.
              </p>
            </div>
            <div>
              <p className="sub-heading">
                Sofern Sie uns hierzu ihre ausdrückliche Einwilligung erteilt
                haben, geben wir Ihren Namen und E-Mailadresse an unseren
                Dienstleister Costumer Alliance GmbH weiter. Dieser wird Sie
                anschreiben und Sie um eine Bewertung des von Ihnen gebuchten
                Feriendomizils und unseren Leistungen bzw. zusätzlichen
                Serviceleistungen bitten.
              </p>
              <p>
                Die Übermittlung erfolgt auf Basis von Art. 6 Abs. 1 lit. a
                DSGVO aufgrund ihrer Einwilligung und nur zu dem Zweck, dass Sie
                über die Plattform unseres Dienstleisters Bewertungen abgeben
                können. Eine weitergehende Verwendung ihrer personenbezogenen
                Daten durch unseren Dienstleister erfolgt nicht.
              </p>
              <p>
                Durch Ihre Teilnahme an der Bewertung erklären Sie sich auch
                damit einverstanden, dass Ihre Bewertungen unter Ihrem Vornamen
                / Benutzername über die Plattform im Internet öffentlich
                zugänglich gemacht werden und damit für Dritte sichtbar sind.
              </p>
              <p>
                Sie können ihre Einwilligung in die Übermittlung und Verwendung
                ihrer Daten an unseren Dienstleister zur Bewertung des bei uns
                gebuchten Feriendomizils und der zusätzlichen Serviceleistungen
                über die Bewertungsplattform jederzeit mit Wirkung für die
                Zukunft widerrufen, indem Sie eine E-Mail an&nbsp;
                <ListinfoDataProtectionMailLink />
                &nbsp;unter Angabe ihres Namens und der E-Mailadresse bzw. Ihres
                Benutzernamens verbunden mit der Mitteilung über den Widerruf
                ihrer Einwilligung, senden. Dies gilt auch für ihr
                Einverständnis in die Veröffentlichung ihrer Bewertung.
              </p>
            </div>
          </div>
          {/* 8 */}
          <div className="col-md-12">
            <h5>
              Nutzung Ihrer Daten für Werbezwecke (Produktempfehlungen an
              Bestandskunden/Newsletter-Abonnement)
            </h5>
            <div>
              <h6 className="sub-heading">
                Produktempfehlungen an Bestandskunden
              </h6>
              <p>
                Wenn Sie bei uns über die Website Feriendomizile, die Anreise
                oder zusätzliche Serviceleistungen wie den Einkaufs-, Feinkost-,
                Wäsche- und unseren Strandkorbservice oder unsere Angebote um
                das Housekeeping gebucht haben und hierbei Ihre E-MailAdresse
                angegeben haben, erlauben wir uns im Rahmen der gesetzlichen
                Vorgaben, Ihnen Empfehlungen für eigene ähnliche Leistungen zu
                übermitteln, wenn wir Sie darauf bei Vertragsabschluss
                hingewiesen haben und Sie dem nicht widersprochen haben. Diese
                Art der Ansprache erfolgt ausschließlich zum Zwecke der
                Übermittlung von sog. Produktempfehlung per elektronischer Post
                an Sie als Bestandskunde/Bestandskundin. Wir verfolgen damit
                unser berechtigtes Interesse an der personalisierten
                Direktwerbung von Bestandskunden. Die Rechtfertigungsgrundlage
                dafür ergibt sich nach Art. 6 Absatz 1 lit. f DSGVO in
                Verbindung mit § 7 Abs. 3 Gesetz gegen den unlauteren Wettbewerb
                (UWG). Haben Sie der Nutzung Ihrer Mailadresse zu diesem Zweck
                anfänglich widersprochen, versenden wir die genannten
                Informationen nicht per E-Mail an Sie. Sie sind berechtigt, der
                Nutzung Ihrer E-Mailadresse zu dem vorbezeichneten Werbezweck
                jederzeit mit Wirkung für die Zukunft durch eine Mitteilung an
                uns zu widersprechen. Nach Eingang Ihres Widerspruchs wird die
                Nutzung Ihrer E-Mailadresse zum vorgenannten Werbezweck
                unverzüglich eingestellt.
              </p>
            </div>
            <div>
              <h6 className="sub-heading">Newsletter-Abonnement</h6>
              <p>
                Sie können sich auf unserer Website für unserem
                E-Mail-Newsletter anmelden. Unser Newsletter informiert
                regelmäßig über Neuigkeiten, interessante Angebote Rund um die
                Insel Sylt und unsere Feriendomizile. Für das
                Newsletter-Abonnement verwenden wir das sog. Double-Opt-in
                Verfahren. Zu diesem Zweck übermitteln wir Ihnen nach der
                Erteilung der Einwilligung zum Newsletter-Abonnement eine
                Bestätigungs-E-Mail. In dieser E-Mail bitten wir Sie, den darin
                enthaltenen Link zu bestätigen, um das Newsletter-Abonnement zu
                bestätigen. Erst nach dieser (zweiten) Aktivierung erhalten Sie
                unsere Newsletter.
              </p>
            </div>
            <div>
              <h6 className="sub-heading">
                Einwilligung bei Newsletter-Abonnement
              </h6>
              <p>
                Die mit der Einwilligung zum Newsletter-Abonnement übermittelte
                E-Mail-Adresse sowie ggf. die Angaben zu Ihrem Namen nutzen wir
                ausschließlich zur Übermittlung unseres Newsletters per
                elektronischer Post an Sie. Diese Übermittlung ist aufgrund
                Ihrer Einwilligung nach Art. 6 Abs. 1 lit. a DSGVO
                gerechtfertigt
              </p>
              <p>
                Ihre einmal erteilte Einwilligung in den Empfang von Newslettern
                per E-Mail zum vorstehend genannten Zweck können Sie jederzeit
                mit Wirkung für die Zukunft uns gegenüber per E-Mail oder den
                entsprechenden Link im Newsletter widerrufen. Nach einer solchen
                Abmeldung löschen wir Ihre E-Mailadresse unverzüglich aus
                unserem Newsletter-Verteiler.
              </p>
              <p>
                Die von Ihnen erteilte Einwilligungserklärung protokollieren wir
                elektronisch zum Zwecke der Nachweisbarkeit. Bei der Anmeldung
                zum Newsletter speichern wir zudem Ihre vom Internet
                Service-Provider (ISP) eingetragene IP-Adresse sowie das Datum
                und die Uhrzeit des Newsletter-Abonnements, um einen möglichen
                Missbrauch Ihrer E-Mail-Adresse zu einem späteren Zeitpunkt
                nachvollziehen zu können
              </p>
              <p>
                Haben Sie uns für das Newsletter-Abonnement keine Einwilligung
                erteilt oder diese widerrufen, erhalten Sie von uns
                elektronische Post nur im Zusammenhang mit der Abwicklung von
                Buchungen, die Sie bei uns getätigt haben.
              </p>
            </div>
          </div>
          {/* 9 */}
          <div className="col-md-12">
            <h5>Verwendung von Cookies</h5>
            <div>
              <h6 className="sub-heading">
                Beschreibung, Zweck und Rechtsgrundlage der Datenverarbeitung
              </h6>
              <p>
                Wir setzen auf unserer Website Cookies ein. Hierbei handelt es
                sich um kleine Dateien, die Ihr Browser automatisch erstellt und
                die auf Ihrem Endgerät (Laptop, Tablet, Smartphone o.ä.)
                gespeichert werden, wenn Sie unsere Seite besuchen. Cookies
                richten auf Ihrem Endgerät keinen Schaden an, enthalten keine
                Viren, Trojaner oder sonstige Schadsoftware.
              </p>
              <p>
                In dem Cookie werden Informationen abgelegt, die sich jeweils im
                Zusammenhang mit dem spezifisch eingesetzten Endgerät ergeben.
                Dies bedeutet jedoch nicht, dass wir dadurch unmittelbar
                Kenntnis von Ihrer Identität erhalten.
              </p>
              <p>
                Der Einsatz von Cookies dient einerseits dazu, die Nutzung
                unseres Angebots für Sie angenehmer zu gestalten. So setzen wir
                sogenannte Session-Cookies ein, um zu erkennen, dass Sie
                einzelne Seiten unserer Website bereits besucht haben. Diese
                werden nach Verlassen unserer Seite automatisch gelöscht.
              </p>
              <p>
                Darüber hinaus setzen wir ebenfalls zur Optimierung der
                Benutzerfreundlichkeit temporäre Cookies ein, die für einen
                bestimmten festgelegten Zeitraum auf Ihrem Endgerät gespeichert
                werden. Besuchen Sie unsere Website erneut, um unsere Dienste in
                Anspruch zu nehmen, wird automatisch erkannt, dass Sie bereits
                bei uns waren und welche Eingaben und Einstellungen sie getätigt
                haben, um diese nicht noch einmal eingeben zu müssen.
              </p>
              <p>
                Zum anderen setzten wir Cookies ein, um die Nutzung unserer
                Website statistisch zu erfassen und zum Zwecke der Optimierung
                unseres Angebotes für Sie auszuwerten. Diese Cookies ermöglichen
                es uns, bei einem erneuten Besuch unserer Seite automatisch zu
                erkennen, dass Sie bereits bei uns waren. Diese Cookies werden
                nach einer jeweils definierten Zeit automatisch gelöscht.
              </p>
              <p>
                Nach den gesetzlichen Vorgaben ist das Abspeichern von
                Informationen auf Ihrem Endgerät - z.B. durch das Setzen von
                Cookies oder auch das Abrufen von Informationen (Tracking) -
                grundsätzlich nur dann erlaubt, wenn Sie hierzu zuvor eine
                Einwilligung erteilt haben. Die Erteilung einer Einwilligung
                muss aber dann nicht erfolgen, sofern ein solches Abspeichern /
                Abrufen für das Angebot der Website erforderlich ist (u.a.
                technisch notwendige Cookies).
              </p>
              <p>
                Zu Beginn der Nutzung der Website werden Sie daher über den
                Einsatz von Cookies in Form einer Cookie-Einwilligungs-Plattform
                und unter Verweis auf diese Datenschutzerklärung informiert und
                es wird (abgesehen von den technisch notwendigen Cookies) die
                Einwilligung in das Speichern des Cookies und die damit
                verbundene Verarbeitung der personenbezogenen Daten eingeholt.
              </p>
              <p>
                Ihre Einwilligung ist jederzeit mit Wirkung für die Zukunft
                widerruflich. Hierfür können Sie über die über die Website
                abrufbare Cookie-Einwilligungs-Plattform Ihre Einstellungen
                ändern. Darüber hinaus haben Sie die Möglichkeit, die
                Speicherung der Cookies zu verhindern, indem Sie entsprechende
                Einstellungen in Ihrem Browser vornehmen.
              </p>
              <p>
                Sofern eine Einwilligung von Nutzern in das Setzen von Cookies
                eingeholt werden muss, erfolgt das Setzen von Cookies für die
                Einwilligungs-Plattform zu dem Zweck die gesetzlich
                vorgeschriebene Zustimmung zur Verwendung von Cookies zu
                erhalten, Art. 6 Abs. 1 lit. c DSGVO.
              </p>
              <p>
                Die Verarbeitung personenbezogener Daten unter Verwendung von
                technisch notwendigen Cookies erfolgt auf Grundlage von Art. 6
                Abs. 1 lit. f DSGVO. Wir haben ein berechtigtes Interesse an der
                Speicherung von Cookies zur technisch fehlerfreien und
                optimierten Bereitstellung seiner Dienste, weil wir dadurch
                unser Angebot technisch bedarfsgerecht anpassen können und für
                Sie der Aufruf unserer Seiten einfacher wird.
              </p>
              <p>
                Cookies zur statistischen Auswertung der Website und Cookies von
                Drittanbietern werden eingesetzt, um die Qualität unserer
                Website zu verbessern und unser Angebot zu optimieren. Die
                hierdurch erhobenen Daten werden von uns nur in
                pseudonymisierten Nutzerprofilen verwendet. Wir nutzen
                insbesondere die Angebote der nachfolgend dargestellten
                Drittanbieter, um mit deren Hilfe auf unsere Angebote aufmerksam
                zu machen und Ihnen die Anfahrt zu unseren Standorten zu
                erleichtern. Diese Verarbeitung nehmen wir nur vor, wenn Sie uns
                dafür Ihre Einwilligung in den Einsatz dieser Cookies für
                Analyse & Statistik erteilt haben (Art. 6 Abs. 1 lit. a DSGVO)
              </p>
            </div>
            <div>
              <h6 className="sub-heading">
                Cookies und Social Media Auftritte von Drittanbietern
              </h6>
              <p>
                Sofern Sie eine entsprechende Einwilligung erteilt haben, werden
                bei Ihrem Besuch unserer Website auch Cookies von
                Partnerunternehmen auf Ihrem Anzeigegerät gespeichert (Cookies
                von Drittanbietern). Dies erfolgt, um unser Internetangebot für
                Sie interessanter zu gestalten. Der Einsatz derartiger Cookies
                und der Umfang der damit jeweils erhobenen Daten ist nachfolgend
                genauer erläutert.
              </p>
              <p>
                Die von uns über unsere Website eingesetzten Cookies von dritten
                Anbietern führen nicht zu einer Datenverarbeitung in den USA.
                Allerdings führen wir Social Media Auftritte in Sozialen
                Netzwerken, die zum Teil zu einer Datenverarbeitung in den USA
                führen können. Diese Anbieter (z. B. Facebook, Instagram) haben
                sich verpflichtet, die Datenschutzbestimmungen des
                EU-US-Datenschutzschildes zu beachten, des Rechtsrahmens für die
                transatlantische Übermittlung von Daten, auf den sich die
                Europäische Kommission und die Vereinigte Staaten geeinigt haben
                (DURCHFÜHRUNGSBESCHLUSS (EU) 2016/1250 DER KOMMISSION vom 12.
                Juli 2016 gemäß der Richtlinie 95/46/EG des Europäischen
                Parlaments und des Rates über die Angemessenheit des vom
                EU-US-Datenschutzschild gebotenen Schutzes (Bekannt gegeben
                unter Aktenzeichen C(2016) 4176)). Zudem sind diese Anbieter
                beim „Privacy Shield“-Programm des US-Handelsministeriums
                registriert. Allerdings hat der Europäische Gerichtshof dieses
                Abkommen für ungültig erklärt und festgestellt, dass die USA
                kein mit der EU vergleichbares Datenschutzniveau besitzen (EuGH,
                Urt. v. 16.07.2020 – C-311/18, Rn. 200, Facebook/Schrems II).
                Die Gesetze der USA geben verschiedenen Sicherheitsbehörden
                Überwachungsbefugnisse, auch durch Einsatz von
                Überwachungsprogrammen, die eine Erhebung und Auswertung von
                Daten möglich machen. Die US-amerikanischen Anbieter sind nach
                den nationalen Gesetzen verpflichtet, den Sicherheitsbehörden
                Zugriff auf die bei ihnen verarbeiteten Daten zu gewähren, auch
                wenn diese bei einer Auslandsgesellschaft verarbeitet werden.
                Mit Erteilung der Einwilligung besteht die Gefahr, dass die über
                Cookies erhobenen Daten Teil einer Überwachung in den USA
                werden. Gegen eine solche Überwachung stehen in den USA kein
                Rechtsbehelf oder ein effizientes gerichtliches Verfahren zur
                Verfügung.
              </p>
              <p>
                Nutzer können dies vermeiden, indem sie dem Setzen der Cookies
                von Drittanbietern aus den USA nicht zustimmen.
              </p>
            </div>
            <div>
              <h6 className="sub-heading">
                Dauer der Speicherung, Widerspruchsmöglichkeit
              </h6>
              <p>
                Session-Cookies werden nach Ende Ihres Besuchs automatisch
                gelöscht. Andere Cookies bleiben auf Ihrem Endgerät für einen
                bestimmten Zeitraum gespeichert oder bis Sie diese löschen,
                sogenannte „temporäre Cookies“. Diese Cookies ermöglichen es uns
                oder unseren Partnerunternehmen (Cookies von Drittanbietern),
                Ihren Browser beim nächsten Besuch wiederzuerkennen. Temporä re
                Cookies werden automatisiert nach einer vorgegebenen Dauer
                gelöscht, die sich je nach Cookie unterscheiden kann.
              </p>
              <p>
                Sie können Ihren Browser so einstellen, dass Sie über das Setzen
                von Cookies informiert werden und Cookies nur im Einzelfall
                erlauben, die Annahme von Cookies für bestimmte Fälle oder
                generell ausschließen sowie das automatische Löschen der Cookies
                beim Schließen des Browsers aktivieren. Bei der Deaktivierung
                von Cookies kann die Funktionalität dieser Website eingeschränkt
                sein.
              </p>
              <p>
                Wie dies im Einzelnen vorzunehmen ist, kann den
                Hilfe-Informationen des Browsers entnommen werden.
              </p>
              <p>
                Diese finden Sie für die jeweiligen Browser unter den folgenden
                Links:
              </p>
              <ul className="list-group">
                <li>
                  Edge:
                  <a
                    href={EDGE_COOKIE_LINK}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {EDGE_COOKIE_LINK}
                  </a>
                </li>
                <li>
                  Firefox:
                  <a
                    href={FIREFOX_COOKIE_LINK}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {FIREFOX_COOKIE_LINK}
                  </a>
                </li>
                <li>
                  Chrome:
                  <a
                    href={CHROME_COOKIE_LINK}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {CHROME_COOKIE_LINK}
                  </a>
                </li>
                <li>
                  Safari:
                  <a
                    href={SAFARI_COOKIE_LINK}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {SAFARI_COOKIE_LINK}
                  </a>
                </li>
                <li>
                  Opera:
                  <a
                    href={OPERA_COOKIE_LINK}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {OPERA_COOKIE_LINK}
                  </a>
                </li>
              </ul>
              <p>
                Alternativ können Sie sich bei der Digital Advertising Alliance
                unter der Internetadresse www.aboutads.info über das Setzen von
                Cookies informieren und Einstellungen hierzu vornehmen
              </p>
            </div>
            <div>
              <h6 className="sub-heading">Übersicht zu eingesetzten Cookies</h6>
              <p>Wir setzen folgende Cookies ein:</p>
            </div>
          </div>
          {/* 10 */}
          <div className="col-md-12">
            <h5>Webanalysedienste Matomo</h5>
            <div>
              <p>
                Wir nutzen auf unserem Online-Angebot das Open-Source
                Webanalyse-Tool Matomo (ehemals Piwik; siehe dazu auch
                www.matamo.org). Dadurch erhalten wir anonymisierte Berichte
                über die Nutzung und die Besucher unserer Online-Angebote,
                insbesondere der verwendeten Suchmaschinen und Schlüsselwörter,
                die verwendeten Sprachen, aufgerufenen Seiten und
                heruntergeladenen Dateien
              </p>
              <p>
                Auch Matomo verwendet “Cookies”, die auf Ihrem Anzeigegerät
                gespeichert werden und die eine Analyse der Benutzung unserer
                Online-Angebote zu Marketing und Optimierungszwecken
                ermöglichen. Die durch den Einsatz von Matomo erzeugten
                Informationen über die Nutzung unserer Online-Angebote werden
                auf unserem eigenen Server in Deutschland gespeichert und
                ausschließlich intern ausgewertet.
              </p>
              <p>
                IP-Adresse werden nur gekürzt erfasst und vor deren Speicherung
                anonymisiert, sodass kein Personenbezug zu Besuchern unseres
                Online-Angebots hergestellt werden kann. Die mit Piwik erhobenen
                Daten dienen lediglich der statistischen Auswertung der
                Nutzerzugriffe zur Verbesserung unserer Online-Angebote und
                werden auch zu einem späteren Zeitpunkt nicht mit
                personenbezogenen Daten zusammengeführt. Diese Daten geben wir
                nicht an Dritte weiter.
              </p>
              <p>
                Sie können der Matomo Analyse widersprechen. Hierfür gibt es
                verschiedene Möglichkeiten:
              </p>
              <ul className="list-group">
                <li>
                  Browser-Einstellung: Sie können die Installation des
                  Matomo-Cookies durch eine entsprechende Einstellung Ihrer
                  Browser-Software verhindern. Sofern Sie die Analyse Ihres
                  Webverhaltens generell unterbinden möchten, können Sie die „Do
                  Not Track“- Option („Nicht-Verfolgen-Verfahren") aktueller
                  Webbrowser in Ihrem Browser aktivieren.
                </li>
                <li>
                  Widerspruch gegen Datenerfassung: Sie können in Ihrem Browser
                  das Ablegen von Webanalyse-Cookie verhindern. In diesem Fall
                  werden keine statistischen Daten erfasst und ausgewertet. Wenn
                  Sie sich für diese Möglichkeit entscheiden möchten, finden Sie
                  unter dem nachfolgend genannten Link die Möglichkeit, durch
                  Setzen des entsprechenden Hakens, den
                  Matomo-Deaktivierungs-Cookie in Ihrem Browser abzulegen:&nbsp;
                  <a
                    href={MATOMO_PRIVACY_POLICY_LINK}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {MATOMO_PRIVACY_POLICY_LINK}
                  </a>
                </li>
              </ul>
              <p>
                Sollten Sie die in Ihrem Browser abgelegten Cookies entfernen,
                ist zu beachten, dass auch der Matomo-Deaktivierungs-Cookie
                dieser Website gelöscht wird. Der MatomoDeaktivierungs-Cookie
                gilt nur für das konkrete Anzeigegerät, auf welchem er abgelegt
                wurde. Wenn Sie ein anderes Anzeigegerät verwenden, müsste der
                Matomo Analyse erneut widersprochen werden. Haben Sie eine der
                vorstehenden Deaktivierungen vorgenommen, stehen Ihnen
                möglicherweise nicht alle Funktionen unserer Website in vollem
                Umfang für eine Nutzung zur Verfügung.
              </p>
              <p>Variante 2 ohne Cookies:</p>
              <p>
                Wir nutzen auf unserem Online-Angebot das Open-Source
                Webanalyse-Tool Matomo (ehemals Piwik; siehe dazu auch
                www.matamo.org). Dadurch erhalten wir anonymisierte Berichte
                über die Nutzung und die Besucher unserer Online-Angebote,
                insbesondere der verwendeten Suchmaschinen und Schlüsselwörter,
                die verwendeten Sprachen, aufgerufenen Seiten und
                heruntergeladenen Dateien.
              </p>
              <p>
                Wir setzen Matomo in einer Variante ohne Cookies ein. Die von
                Matomo generierten Daten ermöglichen die Analyse der Benutzung
                unserer Online-Angebote zu Marketing und Optimierungszwecken.
                Die durch den Einsatz von Matomo erzeugten Informationen über
                die Nutzung unserer Online-Angebote werden auf unserem eigenen
                Server in Deutschland gespeichert und ausschließlich intern
                ausgewertet
              </p>
              <p>
                IP-Adresse werden nur gekürzt erfasst: Wir anonymisieren die
                letzten drei Bytes jeder Besucher IP, sodass kein Personenbezug
                zu Besuchern unseres Online-Angebots hergestellt werden kann.
                Die mit Matomo erhobenen Daten dienen ledig-lich der
                statistischen Auswertung der Nutzerzugriffe zur Verbesserung
                unserer On-line-Angebote und werden auch zu einem späteren
                Zeitpunkt nicht mit personen-bezogenen Daten zusammengeführt.
                Diese Daten geben wir nicht an Dritte weiter.
              </p>
              <p>
                Sie können der Matomo Analyse widersprechen. Dazu können Sie den
                folgenden Opt-Out nutzen. Sollten Sie später Ihre Meinung
                ändern, können Sie jederzeit zu dieser Seite zurückkehren und
                Ihre Einstellungen ändern.
              </p>
            </div>
          </div>
          <div className="col-md-12">
            <h5>Soziale Medien</h5>
            <div>
              <h6 className="sub-heading">Vimeo</h6>
              <p>
                Auf unserer Website sehen Sie zunächst nur inaktive Screenshots
                von Vimeo Videos. Es findet noch kein Datentransfer an Vimeo
                statt. Erst, wenn Sie diesen Screenshot anklicken, wird über
                Ihren Webbrowser in einem gesonderten Tab (Reiterkarte) eine
                Verbindung zu Vimeo hergestellt. Sie befinden sich ab diesem
                Zeitpunkt auf den Seiten des Anbieters Vimeo.
              </p>
              <p>
                Vimeo ist ein Videoportal, das das Streaming unserer Videos in
                hoher Auflösung ermöglicht. Dienstanbieter ist die Vimeo LLC mit
                Hauptsitz in 555 West 18th Street, New York, New York 10011,
                USA.
              </p>
              <p>Bei diesem Anbieter betreiben wir auch einen eigenen Kanal.</p>
              <p>
                Vimeo sammelt über Cookies automatisch bestimmte Arten von
                Daten, wenn Sie Vimeo Dienste nutzen, unabhängig davon, ob Sie
                ein Konto bei Vimeo haben oder nicht. Zu diesen Daten gehören
                Ihre IP-Adresse, technische Informationen über Ihr Gerät (z.B.
                Browsertyp, Betriebssystem, grundlegende Geräteinformationen),
                die Webseite, die Sie besucht haben oder die Suchanfrage, die
                Sie eingegeben haben, bevor Sie Vimeo erreicht haben, sowie Ihre
                Aktivitäten.
              </p>
              <p>
                Wenn Sie ein Video direkt auf Vimeo (z. B. in unserem
                Vimeo-Kanal) aufrufen, werden Ihre Daten möglicherweise an einen
                Server von Vimeo in den USA übertragen und dort wie zuvor
                beschrieben verarbeitet. Vimeo wertet auf diese Weise Ihre
                Nutzung unseres Videoangebots bei Vimeo aus, um anonymisierte
                Berichte über die Videoabrufe für uns zusammenzustellen und um
                weitere mit der Videonutzung verbundene Dienstleistungen an uns
                zu erbringen. Diese Datenverarbeitung erfolgt nur auf der
                Grundlage der von Ihnen erteilten Einwilligung (Art. 6 Abs. 1
                Buchstabe a DSGVO).
              </p>
              <p>
                Sind Sie während des Abspielens von Vimeo Videos als Mitglied
                bei Vimeo eingeloggt, ordnet Vimeo die Information über das
                Abspielen des Videos Ihrem persönlichen Benutzerkonto zu. Sie
                können diese Zuordnung verhindern, indem Sie sich aus Ihrem
                Vimeo-Benutzerkonto abmelden und etwaige Cookies, die die Vimeo
                Seite bei Ihnen gesetzt hat, löschen.
              </p>
              <p>
                Für die Nutzung unseres Vimeo-Kanals haben wir mit Vimeo einen
                Vertrag zwischen gemeinsam Verantwortlichen abgeschlossen (Art.
                26 DSGVO). Darin haben wir uns verpflichtet, Sie über diese
                Datenverarbeitung bei Nutzung unseres Kanals auf Vimeo zu
                informieren.
              </p>
              <p>
                Weitere Informationen zum Datenschutz bei Vimeo finden Sie in
                der Datenschutzerklärung des Anbieters unter:&nbsp;
                <a
                  href={VIMEO_PRIVACY_POLICY_LINK}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {VIMEO_PRIVACY_POLICY_LINK}
                </a>
              </p>
              <p>
                Die Vimeo, LLC ist unter dem EU-US Privacy Shield zertifiziert,
                bietet aber hierdurch allein kein angemessenes Datenschutzniveau
                an:&nbsp;
                <a
                  href={US_PRIVACY_SHIELD_VIMEO_LINK}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {US_PRIVACY_SHIELD_VIMEO_LINK}
                </a>
              </p>
            </div>
            <div>
              <h6 className="sub-heading">
                Social Media Auftritte und Verwendung von Social Media Icons auf
                unseren Seiten
              </h6>
              <p>
                Auf unserer Website nutzen wir keine Social-Plug-ins als aktive
                Schaltflächen. Wir verweisen lediglich über Icons auf unser
                Angebot in folgenden sozialen Netzwerken:
              </p>
              <ul className="list-group">
                <li>
                  Instagram: Facebook Ireland Limited, 4 Grand Canal Square,
                  Grand Canal Harbour, Dublin 2, Ireland;
                </li>
                <li>
                  Facebook: Facebook Ireland Limited, 4 Grand Canal Square,
                  Grand Canal Harbour, Dublin 2, Ireland;
                </li>
                <li>
                  Pinterest: Pinterest Europe Ltd., Palmerston House, 2nd Floor,
                  Fenian Street, Dublin 2, Irland.
                </li>
              </ul>
              <p>
                Die Social Media Icons zeigen wir auf unserer Seite nur an. Sie
                sind als inaktive Icons gestaltet. Zur Wahrung des Datenschutzes
                setzen wir eine Lösung ein, die an diese Dienste lediglich die
                Adresse unseres Servers und nicht Ihre IP-Adresse übermittelt,
                falls Sie ein Social Media Icon durch Anklicken aktiviert haben.
              </p>
              <p>
                Wenn Sie auf ein solches Social Media Icon auf unserer Website
                klicken, wird es mit Ihrer Einwilligung aktiviert und über Ihren
                Webbrowser in einem gesonderten Tab (Reiterkarte) eine
                Verbindung zu diesen Drittanbietern hergestellt. Diese
                Drittanbieter können dadurch den Besuch auf unseren Seiten
                verfolgen. Sofern Sie Mitglied in einem der sozialen Netzwerke
                sind, können Sie durch Aktivieren der Schaltfläche den Inhalt
                unserer Seite mit anderen Mitgliedern aus Ihrem sozialen
                Netzwerk teilen.
              </p>
              <p>
                Durch Ihre Teilnahme an sozialen Netzwerken oder durch den
                Besuch oder den Aufruf unserer Social Media Auftritte können
                Ihre Daten außerhalb der EU verarbeitet werden. Hierdurch können
                sich Risiken ergeben, weil so z. B. die Durchsetzung Ihrer
                Rechte erschwert sein kann.
              </p>
              <p>
                Mit Aufruf eines sozialen Netzwerks werden in der Regel Cookies
                zur Erfassung des Nutzerverhaltens auf Ihrem Endgerät
                gespeichert. Sofern Sie ein Nutzerkonto auf dem jeweiligen
                Netzwerk haben und dort angemeldet sind, kann Ihr
                Nutzungsverhalten zu Ihrem Nutzerkonto gespeichert werden. Die
                sozialen Netzwerke können das Nutzungsverhalten analysieren und
                für Marktforschungs- und Werbezwecke verwenden. Dies kann dazu
                führen, dass Ihnen innerhalb und außerhalb der sozialen
                Netzwerke Werbung angezeigt wird. Wir haben hierauf keinen
                Einfluss.
              </p>
              <p>
                Wir haben keinen Einfluss auf die zu Ihrer Person von den
                sozialen Netzwerken erhobenen und gespeicherten Daten. Über
                unsere o. g. Social Media Auftritte erhalten wir Auswertungen zu
                Nutzerdaten und können Nutzer/Nutzerinnen mit
                interessengerechter Werbung ansprechen. Interagieren Nutzer mit
                unserem Social Media Auftritt und sind mit einem Nutzerkonto
                angemeldet, können wir grundsätzlich auch das Nutzerprofil
                erkennen und die Inhalte von Kommentaren oder Postings zu
                unserem Auftritt sehen. Diese Datenverarbeitung erfolgt daher in
                gemeinsamer Verantwortung mit dem jeweiligen Anbieter des
                sozialen Netzwerks. Für die Auswertung der Daten im Zusammenhang
                mit unseren Social Media Auftritten haben wir daher mit den
                Anbietern jeweils einen Vertrag über die gemeinsame
                Verantwortung abgeschlossen (Art. 26 DSGVO). Darin haben wir uns
                verpflichtet, Ihnen diese Informationen zum Datenschutz zukommen
                zu lassen. Weitergehende Informationen finden Sie in den
                Datenschutzbestimmungen der jeweiligen sozialen Netzwerke. Sie
                können die Ihnen zustehenden Rechte auch uns gegenüber geltend
                machen. Allerdings kann der Anbieter des sozialen Netzwerks Ihre
                Rechte umfassender erfüllen, weil dort auch die Daten zur
                Nutzung und Auswertung gespeichert sind.
              </p>
              <h6>Instagram</h6>
              <p>
                Wir betreiben unter&nbsp;
                <a
                  href={LISTINFO_INSTAGRAM_LINK}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {LISTINFO_INSTAGRAM_LINK}
                </a>
                &nbsp;einen Social Media Auftritt, über den wir Fotos und Posts
                zu unseren Feriendomizilen darstellen, über unsere Leistungen
                informieren und mit Nutzern und Feriengästen kommunizieren. Bei
                Nutzung und Aufruf unserer Instagram-Seite werden Nutzerdaten
                auch von der in Irland ansässigen Gesellschaft Facebook Ireland
                Ltd., 4 Grand Canal Square, Grand Canal Harbour, Dublin sowie
                der in den USA ansässigen Facebook Inc., 1601 Willow Road, Menlo
                Park, California 94025 verarbeitet werden (im Folgenden
                „Facebook“). Über Instagram ermöglicht Facebook u. a. ein
                System, indem Facebook Werbung über sein Netzwerk verbreitet.
              </p>
              <p>
                Die Aufrufe und Interaktionen auf unserer Instagram-Seite
                analysieren wir. Hierzu erstellt Facebook Nutzungsprofile,
                stellt uns diesbezüglich aber ausschließlich anonyme Daten zur
                Verfügung, sogenannte Seiten-Insights. Hierbei handelt es sich
                um zusammengefasste Daten, durch die wir Aufschluss darüber
                erhalten, wie Nutzer/Nutzerinnen mit unserer Instagram-Seite
                interagieren. Die erstellten Statistiken werden ausschließlich
                in anonymisierter Form an uns übermittelt. Wir haben keinen
                Zugang zu den jeweils zugrundliegenden Daten. Bezogen auf diesen
                Insight-Dienst verarbeiten wir gemeinsam mit Facebook Ihre
                personenbezogenen Daten.
              </p>
              <p>
                Aus diesem Grund haben wir den Vertrag zwischen gemeinsam
                Verantwortlichen geschlossen.
              </p>
              <p>
                Unsere Instagram-Seite können Sie unabhängig davon aufrufen, ob
                Sie bei Instagram selbst ein Nutzerkonto haben oder nicht. Dabei
                verarbeiten wir Ihre personenbezogenen Daten, wenn Sie mit
                unserer Instagram-Seite interagieren, z. B. einen Kommentar
                abgeben, einen Like-Button klicken oder uns eine Nachricht
                schicken. Wir geben die Daten nicht an Dritte weiter. Maßgeblich
                sind auch die Nutzungsbedingungen von Facebook unter:&nbsp;
                <OpenInNewTab link={INSTAGRAM_HELP_LINK} />
              </p>
              <p>
                Rechtsgrundlage für diese Datenverarbeitungen ist je nach Art
                Ihrer Aktivität Ihre Einwilligung (Art. 6 Abs. 1 Buchstabe a
                DSGVO) oder unser berechtigtes Interesse (Art 6 Abs. 1 Buchstabe
                f DSGVO) an einer kundengerechten Vermarktung.
                Nutzer/Nutzerinnen von Instagram können eine erteilte
                Einwilligung bei der Veröffentlichung ihres Kommentars oder
                Likes jederzeit mit Wirkung für die Zukunft widerrufen, indem
                sie den Kommentar oder den betreffenden Inhalt löschen. Durch
                einen Widerruf wird die Rechtmäßigkeit der bis zum Widerruf
                aufgrund der Einwilligung erfolgten Verarbeitung nicht berührt.
              </p>
              <p>
                Facebook bietet die Möglichkeit, bestimmten Datenverarbeitungen
                zu widersprechen; diesbezügliche Hinweise und
                Opt-Out-Möglichkeiten finden sich unter&nbsp;
                <OpenInNewTab link={FACEBOOK_POLICY_COOKIE_LINK} />
                &nbsp;und für eingeloggte Nutzer unter&nbsp;
                <OpenInNewTab link={FACEBOOK_SETTINGS_ADS_LINK} />.
              </p>
              <p>
                Nutzer/Nutzerinnen von Instagram können unter&nbsp;
                <OpenInNewTab link={FACEBOOK_ADS_PREFERENCE_LINK} />
                &nbsp;beeinflussen, inwieweit ihr Nutzungsverhalten bei dem
                Besuch auf unserer Instagram-Seite erfasst werden darf. Zudem
                können entsprechende Einstellungen unter&nbsp;
                <OpenInNewTab link={FACEBOOK_SETTINGS_LINK} />
                &nbsp;und&nbsp;
                <OpenInNewTab link={INSTAGRAM_LOGIN_TO_PRIVACY_LINK} />
                &nbsp;vorgenommen werden oder der Datenverarbeitung unter:&nbsp;
                <OpenInNewTab link={FACEBOOK_HELP_CONTACT_LINK} />
                &nbsp;widersprochen werden.
              </p>
              <p>
                Eine Datenverarbeitung über von Facebook eingesetzte Cookies
                kann auch durch Einstellungen im Browser unterbunden werden.
              </p>
              <p>
                Facebook überträgt Nutzerdaten nur in Länder, für die ein
                Angemessenheitsbeschluss der Europäischen Kommission nach Art.
                45 DSGVO vorliegt oder auf Grundlage geeigneter Garantien nach
                Art. 46 DSGVO. Die Facebook Inc. ist mit allen verbundenen
                Unternehmen unter dem EU-US Privacy Shield zertifiziert, bietet
                aber hierdurch allein kein angemessenes Datenschutzniveau an: (
                <OpenInNewTab link={US_PRIVACY_SHIELD_META_LINK} />
                ).
              </p>
              <h6>Pinterest</h6>
              <p>
                Wir betreiben unter&nbsp;
                <OpenInNewTab link={PINTEREST_LIGHTHOUSE_LINK} />
                &nbsp;Social Media Auftritte, über die wir Pins (Fotos, Links
                und Text) zu unserem Unternehmen darstellen, über unsere
                Leistungen informieren und mit Kunden/Kundinnen kommunizieren.
                Bei Nutzung und Aufruf unserer Pinterest-Seite werden
                Nutzerdaten auch von der in Irland ansässigen Gesellschaft
                Pinterest Europe Ldt., Palmerston House, 2nd Floor, Fenian
                Street, Dublin 2, Ireland, in den USA von Pinterest Inc., 505
                Brannan Street, Suite 900, San Francisco, CA 94107,USA
                verarbeitet (im Folgenden „Pinterest“). Pinterest bietet
                unter&nbsp;
                <OpenInNewTab link={PINTEREST_ADS_LINK} />
                &nbsp;auch ein System zur Verbreitung von Werbung auf Pinterest
                an.
              </p>
              <p>
                Die Aufrufe und Interaktionen auf unserer Pinterest-Seite
                analysieren wir. Hierzu erstellt Pinterest Nutzungsprofile,
                stellt uns diesbezüglich unter&nbsp;
                <OpenInNewTab link={PINTEREST_ANALYTICS_LINK} />
                &nbsp;aber ausschließlich anonyme Daten zur Verfügung,
                sogenannte Audience Insights. Hierbei handelt es sich um
                zusammengefasste Daten, durch die wir Aufschluss darüber
                erhalten, wie Nutzer/Nutzerinnen mit unserer Pinterest-Seite
                interagieren. Die erstellten Statistiken werden ausschließlich
                in anonymisierter Form an uns übermittelt. Wir haben keinen
                Zugang zu den jeweils zugrundliegenden Daten. Bezogen auf diesen
                Audience Insights-Dienst verarbeiten wir gemeinsam mit Pinterest
                Ihre personenbezogenen Daten. Aus diesem Grund haben wir den
                Vertrag zwischen gemeinsam Verantwortlichen geschlossen.
              </p>
              <p>
                Unsere Pinterest-Seite können Sie unabhängig davon aufrufen, ob
                Sie bei Pinterest selbst ein Nutzerkonto haben oder nicht. Dabei
                verarbeiten wir Ihre personenbezogenen Daten, wenn Sie mit
                unserer Pinterest-Seite interagieren, z. B. einen Kommentar
                abgeben, einen Like-Button klicken, unseren Pin re-pinnen oder
                uns eine Nachricht schicken. Wir geben die Daten nicht an Dritte
                weiter. Maßgeblich sind die Bestimmungen zum Datenschutz von
                Pinterest unter:&nbsp;
                <OpenInNewTab link={PINTEREST_PRIVACY_POLICY_LINK} />
              </p>
              <p>
                Innerhalb der EU ist Rechtsgrundlage für diese
                Datenverarbeitungen Ihre Einwilligung (Art. 6 Abs. 1 Buchstabe a
                DSGVO). Nutzer/Nutzerinnen von Pinterest können eine erteilte
                Einwilligung bei der Veröffentlichung ihres Kommentars oder
                Likes oder Pins jederzeit mit Wirkung für die Zukunft
                widerrufen, indem sie den Kommentar oder den betreffenden Inhalt
                löschen. Durch einen Widerruf wird die Rechtmäßigkeit der bis
                zum Widerruf aufgrund der Einwilligung erfolgten Verarbeitung
                nicht berührt.
              </p>
              <p>
                Pinterest bietet die Möglichkeit, bestimmten Datenverarbeitungen
                zu widersprechen; diesbe-zügliche Hinweise und
                Opt-Out-Möglichkeiten finden sich unter&nbsp;
                <OpenInNewTab link={PINTEREST_PRIVACY_SETTINGS_LINK} />.
              </p>
              <p>
                Nutzer/Nutzerinnen von Pinterest können unter&nbsp;
                <OpenInNewTab link={PINTEREST_PRIVACY_SETTINGS_LINK} />
                &nbsp;beeinflussen, inwieweit ihr Nutzungsverhalten bei dem
                Besuch auf unserer Pinterest-Seite erfasst werden darf.
              </p>
              <p>
                Eine Datenverarbeitung über von Pinterest eingesetzte Cookies
                kann auch durch Einstellungen im Browser unterbunden werden.
              </p>
            </div>
          </div>
          <div className="col-md-12">
            <h5>OpenStreetMap</h5>
            <div>
              <p>
                Diese Website nutzt OpenStreetMaps zur Darstellung interaktiver
                Karten und zur Erstellung von Anfahrtsbeschreibungen.
                OpenStreetMaps ein Kartendienst der Open-Street-Map Foundation
                (OSMF), 132 Maney Hill Road, Sutton Coldfield, West Midlands,
                B72 1JU, United Kingdom. Ihnen wird die eingebundene Karte von
                OpenStreetMaps erst nach Erteilung Ihrer Einwilligung angezeigt
                (Art. 6 Abs. 1 lit. a DSGVO).
              </p>
              <p>
                Bei Freischaltung durch Einwilligung und Nutzung von
                OpenStreetMaps über unsere Website können Informationen über die
                Benutzung dieses Dienstes auf unserer Website einschließlich
                Ihrer IP-Adresse an OSMF übertragen und dort gespeichert werden.
                OpenStreetMaps wird diese Informationen auch möglicherweise an
                Dritte übertragen, sofern dies gesetzlich vorgeschrieben ist
                oder soweit Dritte diese Daten im Auftrag von OpenStreetMaps
                verarbeiten.
              </p>
              <p>
                Wir haben keinen Einfluss auf den Umfang der auf diese Weise von
                OpenStreetMaps erhobenen Daten. Wir selbst erheben keine
                personenbezogenen Daten im Zusammenhang mit der Nutzung von
                OpenStreetMaps.
              </p>
              <p>
                Entsprechend unserem Kenntnisstand sind dies zumindest folgende
                Daten:
              </p>
              <ul className="list-group">
                <li>Datum und Uhrzeit des Besuchs auf unserer Webseite,</li>
                <li>Internetadresse oder URL der aufgerufenen Webseite,</li>
                <li>IP-Adresse,</li>
                <li>Standortdaten</li>
                <li>
                  im Rahmen der Routenplanung eingegebene Start- und
                  Zielanschrift.
                </li>
              </ul>
              <p>
                Nähere Informationen folgen aus der Datenschutzerklärung von
                OpenStreetMaps unter:&nbsp;
                <OpenInNewTab link={OPEN_STREET_PRIVACY_POLICY_LINK} />
                &nbsp;und von OpenStreetMaps unter&nbsp;
                <OpenInNewTab link={OPEN_STREET_PRIVACY_POLICY_LINK} />. Die
                Nutzung von OpenStreetMaps erfolgt gemäß den Nutzungsbedingungen
                von OpenStreetMaps unter&nbsp;
                <OpenInNewTab link={OPEN_STREET_FOUNDATION_POLICY_LINK} />
                &nbsp;und den Geschäftsbedingungen unter&nbsp;
                <OpenInNewTab link={OPEN_STREET_TERMS_TO_USE_LINK} />.
              </p>
            </div>
          </div>
          <div className="col-md-12">
            <h5>Links zu anderen Webseiten</h5>
            <div>
              <p>
                Die Website kann gelegentlich Links (interaktive Verweise) auf
                Internetauftritte Dritter, für die Appartementvermittlung
                Familie Clausen nicht verantwortlich ist, enthalten.
                Appartementvermittlung Familie Clausen hat keinerlei Einfluss
                auf Inhalt und Gestaltung der verlinkten externen Seiten bzw.
                der Internetauftritte, auf die der Nutzer über diese Links
                gelangt. Für Inhalt und Gestaltung dieser Internetauftritte
                sowie die Einhaltung datenschutzrechtlicher Bestimmungen sind
                ausschließlich die jeweiligen Anbieter verantwortlich.
              </p>
            </div>
          </div>
          <div className="col-md-12">
            <h5>Schutz Ihrer Daten: SSL- bzw. TLS-Verschlüsselung</h5>
            <div>
              <p>
                Diese Website nutzt aus Sicherheitsgründen und zum Schutz der
                Übertragung vertraulicher Inhalte, wie zum Beispiel Bestellungen
                oder Anfragen, die Sie an uns senden, eine SSL-bzw.
                TLS-Verschlüsselung. Eine verschlüsselte Verbindung erkennen Sie
                daran, dass die Adresszeile des Browsers von “http://” auf
                “https://” wechselt und an dem Schloss-Symbol in Ihrer
                Browserzeile.
              </p>
              <p>
                Wenn die SSL- bzw. TLS-Verschlüsselung aktiviert ist, können die
                Daten, die Sie an uns übermitteln, nicht von Dritten mitgelesen
                werden.
              </p>
              <p>
                Wir weisen aber darauf hin, dass die Datenübertragung im
                Internet (z.B. bei der Kommunikation per E-Mail)
                Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der
                Daten vor dem Zugriff durch Dritte ist nicht möglich.
              </p>
            </div>
          </div>
          {/* 15 */}
          <div className="col-md-12">
            <h5>Fragen zum Datenschutz und Rechte des Nutzers</h5>
            <p>
              Sie haben jederzeit das Recht unentgeltlich Auskunft über
              Herkunft, Empfänger und Zweck Ihrer gespeicherten
              personenbezogenen Daten zu erhalten. Sie haben außerdem ein Recht,
              die Berichtigung, Sperrung oder Löschung dieser Daten zu
              verlangen. Hierzu sowie zu weiteren Fragen zum Thema Datenschutz
              können Sie sich jederzeit unter den in Ziffer 1 angegebenen
              Kontaktdaten an uns wenden. Des Weiteren steht Ihnen ein
              Beschwerderecht bei der zuständigen Aufsichtsbehörde zu. Im
              Einzelnen stehen Ihnen als betroffene Person folgende Rechte
              gegenüber uns als Verantwortliche Stelle zu:
            </p>
            <div>
              <h6 className="sub-heading">Auskunftsrecht</h6>
              <p>
                Sie können eine Bestätigung darüber verlangen, ob
                personenbezogene Daten, die Sie betreffen, von uns verarbeitet
                werden. Liegt eine solche Verarbeitung vor, können Sie über
                folgende Informationen Auskunft verlangen:
              </p>
              <ul className="list-group">
                <li>Verarbeitungszwecke;</li>
                <li>
                  die Empfänger bzw. die Kategorien von Empfängern, gegenüber
                  denen die Sie betreffenden personenbezogenen Daten offengelegt
                  wurden oder noch offengelegt werden;
                </li>
                <li>
                  falls möglich, die geplante Dauer der Speicherung der Sie
                  betreffenden personenbezogenen Daten oder, falls dies nicht
                  möglich ist, Kriterien für die Festlegung der Speicherdauer;
                </li>
                <li>das Bestehen weiterer Rechte, siehe nachfolgend;</li>
                <li>
                  alle verfügbaren Informationen über die Herkunft der Daten,
                  wenn die personenbezogenen Daten nicht bei Ihnen erhoben
                  werden;
                </li>
                <li>
                  das Bestehen einer automatisierten Entscheidungsfindung
                  einschließlich Profiling sowie ggfs. nähere Angaben dazu.
                </li>
              </ul>
              <p>
                Ihnen steht das Recht auf Unterrichtung über die geeigneten
                Garantien nach Art. 46 DSGVO bei Weiterleitung Ihrer Daten an
                ein Drittland oder eine internationale Organisation zu.
              </p>
            </div>
            <div>
              <h6 className="sub-heading">Recht auf Berichtigung</h6>
              <p>
                Sie haben das Recht, von uns unverzüglich die Berichtigung Sie
                betreffender unrichtiger oder unvollständiger personenbezogener
                Daten zu verlangen.
              </p>
            </div>
            <div>
              <h6 className="sub-heading">
                Recht auf Einschränkung der Verarbeitung
              </h6>
              <p>
                Sie haben das Recht, von uns die Einschränkung der Verarbeitung
                zu verlangen, wenn eine der folgenden Voraussetzungen gegeben
                ist:
              </p>
              <ul className="list-group">
                <li>
                  Sie bestreiten die Richtigkeit der personenbezogenen Daten.
                </li>
                <li>
                  Die Verarbeitung der Daten ist unrechtmäßig und Sie lehnen die
                  Löschung der personenbezogenen Daten ab und verlangen
                  stattdessen die Einschränkung der Nutzung der
                  personenbezogenen Daten.
                </li>
                <li>
                  Wir benötigen die personenbezogenen Daten für die Zwecke der
                  Verarbeitung nicht länger, Sie benötigen die Daten jedoch zur
                  Geltendmachung, Ausübung oder Verteidigung von
                  Rechtsansprüchen; oder
                </li>
                <li>
                  Sie haben Widerspruch gegen die Verarbeitung eingelegt (s.
                  nachfolgend) und es steht noch nicht fest, ob unsere
                  berechtigten Gründe gegenüber Ihren überwiegen.
                </li>
              </ul>
            </div>
            <div>
              <h6 className="sub-heading">Recht auf Löschung</h6>
              <p>
                Sie können von uns verlangen, dass die Sie betreffenden
                personenbezogenen Daten unverzüglich gelöscht werden, und wir
                sind verpflichtet, diese Daten unverzüglich zu löschen, sofern
                einer der folgenden Gründe zutrifft:
              </p>
              <ul className="list-group">
                <li>
                  Ihre personenbezogenen Daten sind für die Zwecke nicht mehr
                  notwendig, für die wir diese erhoben oder auf sonstige Weise
                  verarbeitet haben.
                </li>
                <li>
                  Sie widerrufen Ihre erteilte Einwilligung und es fehlt an
                  einer anderweitigen Rechtsgrundlage für die Verarbeitung.
                </li>
                <li>
                  Sie legen Widerspruch (s. nachfolgend) gegen die Verarbeitung
                  ein.
                </li>
                <li>
                  Ihre personenbezogenen Daten wurden unrechtmäßig verarbeitet.
                </li>
                <li>
                  Die Löschung Ihrer personenbezogenen Daten ist für uns zur
                  Erfüllung einer rechtlichen Verpflichtung nach dem Unionsrecht
                  oder dem Recht der Mitgliedsstaaten erforderlich.
                </li>
                <li>
                  Wir haben die personenbezogenen Daten auf der Grundlage der
                  Einwilligung eines Kindes erhoben.
                </li>
              </ul>
            </div>
            <div>
              <h6 className="sub-heading">Recht auf Unterrichtung</h6>
              <p>
                Haben Sie das Recht auf Berichtigung, Löschung oder
                Einschränkung der Verarbeitung gegenüber uns geltend gemacht,
                sind wir verpflichtet, allen Empfängern, denen die Sie
                betreffenden personenbezogenen Daten offengelegt wurden, diese
                Berichtigung oder Löschung der Daten oder Einschränkung der
                Verarbeitung mitzuteilen, es sei denn, dies erweist sich als
                unmöglich oder ist mit einem unverhältnismäßigen Aufwand
                verbunden. Ihnen steht gegenüber uns das Recht zu, über diese
                Empfänger unterrichtet zu werden.
              </p>
            </div>
            <div>
              <h6 className="sub-heading">Recht auf Datenübertragbarkeit</h6>
              <p>
                Sie haben das Recht, die Sie betreffenden personenbezogenen
                Daten, die Sie uns bereitgestellt haben, in einem
                strukturierten, gängigen und maschinenlesbaren Format zu
                erhalten. Außerdem haben Sie das Recht diese Daten einem anderen
                Verantwortlichen ohne Behinderung durch den Verantwortlichen,
                dem die personenbezogenen Daten bereitgestellt wurden, zu
                übermitteln, sofern
              </p>
              <ul className="list-group">
                <li>
                  die Verarbeitung auf einer Einwilligung gem. Art. 6 Abs. 1
                  lit. a DSGVO oder auf einem Vertrag gem. Art. 6 Abs. 1 lit. b
                  DSGVO beruht und
                </li>
                <li>
                  die Verarbeitung mithilfe automatisierter Verfahren erfolgt.
                </li>
              </ul>
              <p>
                In Ausübung dieses Rechts können Sie verlangen, dass die Sie
                betreffenden personenbezogenen Daten direkt von einem
                Verantwortlichen einem anderen Verantwortlichen übermittelt
                werden, soweit dies technisch machbar ist. Freiheiten und Rechte
                anderer Personen dürfen hierdurch nicht beeinträchtigt werden.
                Das Recht auf Datenübertragbarkeit gilt nicht für eine
                Verarbeitung personenbezogener Daten, die für die Wahrnehmung
                einer Aufgabe erforderlich ist, die im öffentlichen Interesse
                liegt oder in Ausübung öffentlicher Gewalt erfolgt, die dem
                Verantwortlichen übertragen wurde.
              </p>
            </div>
            <div>
              <h6 className="sub-heading">Widerspruchsrecht</h6>
              <p>
                Sie haben das Recht, aus Gründen, die sich aus Ihrer besonderen
                Situation ergeben, jederzeit Widerspruch gegen die Verarbeitung
                Sie betreffender personenbezogenen Daten einzulegen, die auf
                einer der folgenden Grundlagen erfolgt:
              </p>
              <ul className="list-group">
                <li>
                  Die Verarbeitung Ihrer personenbezogenen Daten durch uns ist
                  für die Wahrnehmung einer Aufgabe erforderlich, die im
                  öffentlichen Interesse liegt oder in Ausübung öffentlicher
                  Gewalt erfolgt, die uns übertragen wurde; oder
                </li>
                <li>
                  die Verarbeitung ist zur Wahrung unserer berechtigten
                  Interessen oder der eines Dritten erforderlich, sofern nicht
                  Ihre Interessen oder Grundfreiheiten überwiegen, die den
                  Schutz Ihrer personenbezogenen Daten erfordern.
                </li>
              </ul>
              <p>
                Das Recht zum Widerspruch steht Ihnen auch für ein auf diese
                Verarbeitungen gestütztes Profiling zu. Verarbeiten wir Ihre
                personenbezogenen Daten, um Direktwerbung zu betreiben, haben
                Sie das Recht, jederzeit Widerspruch gegen die Verarbeitung
                Ihrer personenbezogenen Daten zum Zwecke derartiger Werbung
                einzulegen. Dies gilt auch für das Profiling, soweit es mit
                solcher Direktwerbung in Verbindung steht.
              </p>
              <p>
                Sie haben zudem das Recht, aus Gründen, die sich aus Ihrer
                besonderen Situation ergeben, gegen die Ihre personenbezogenen
                Daten betreffende Verarbeitung Widerspruch einzulegen, die wir
                zu wissenschaftlichen oder historischen Forschungszwecken oder
                zu statistischen Zwecken vornehmen, es sei denn, die
                Verarbeitung ist zur Erfüllung einer im öffentlichen Interesse
                liegenden Aufgabe erforderlich.
              </p>
            </div>
            <div>
              <h6 className="sub-heading">
                Recht auf Beschwerde bei einer Aufsichtsbehörde
              </h6>
              <p>
                Im Falle datenschutzrechtlicher Verstöße steht dem Betroffenen
                ein Beschwerderecht bei der Aufsichtsbehörde zu. Eine Liste der
                Datenschutzbeauftragten sowie deren Kontaktdaten können
                folgendem Link entnommen werden:
              </p>
              <p>
                <a href={BFDI_LINK} target="_blank" rel="noopener noreferrer">
                  {BFDI_LINK}
                </a>
              </p>
              <p>
                Zuständige Aufsichtsbehörde in datenschutzrechtlichen Fragen ist
                für im Bundesland Schleswig Holstein ansässige Unternehmen das
                Unabhängige Landeszentrum für Datenschutz Schleswig-Holstein
                (Holstenstraße 98, 24103 Kiel, Tel: Telefon: 04 31/988-12 00,
                Telefax: 04 31/988-12 23; E-Mail:&nbsp;
                <a href="mailto:mail@datenschutzzentrum.de">
                  mail@datenschutzzentrum.de
                </a>
                ).
              </p>
            </div>
          </div>
          <div className="col-md-12">
            <h5>Änderungen</h5>
            <p>
              Um zu gewährleisten, dass die Datenschutzerklärung den
              gesetzlichen Vorgaben entspricht, behält Appartementvermittlung
              Familie Clausen sich jederzeit Änderungen vor. Dies gilt auch,
              wenn die Datenschutzerklärung aufgrund einer Änderung unseres
              Angebots über die Website angepasst werden muss. Die neue
              Datenschutzerklärung gilt ab dem nächsten Besuch des Nutzers auf
              der Website.
            </p>
          </div>
        </div>
        <div>
          <h6 className="mt-3">
            Copyright Appartementvermittlung Familie Clausen GmbH © 2023. Alle
            Rechte vorbehalten.
          </h6>
        </div>
      </div>
    </section>
  );
};

export default withLayout(DataProtection);
