import {
  EMAIL_VERIFICATION_SUCCESS,
  HIDE_FLASH_MESSAGE,
  SHOW_FLASH_MESSAGE,
} from "../actionTypes";

export const flashMessageReducer = (
  state = {
    show: false,
    message: "",
    color: "primary",
    emailVerifiedMessage: "",
  },
  action
) => {
  switch (action.type) {
    case SHOW_FLASH_MESSAGE:
      return { ...state, show: true, ...action.payload };
    case HIDE_FLASH_MESSAGE:
      return {
        ...state,
        show: false,
        message: "",
        color: "primary",
      };
    case EMAIL_VERIFICATION_SUCCESS:
      return {
        ...state,
        emailVerifiedMessage: action.payload,
      };
    default:
      return state;
  }
};
