import {
  AUTHENTICATE,
  CLEAR_SERVER_ERROR,
  DEAUTHENTICATE,
  SET_SERVER_ERROR,
} from "../actionTypes";
import httpClient, { serverErrorToErrorList } from "../../services/httpClient";
import jwt from "jsonwebtoken";

export const authenticate = (user) => async (dispatch) => {
  try {
    let response = await httpClient.post(`/login`, user);
    const { token, name, email } = response.data.data;
    const { sub } = jwt.decode(token);
    dispatch({
      type: AUTHENTICATE,
      payload: { token, name, email, user_id: sub },
    });
    localStorage.setItem("token", token);
    localStorage.setItem("name", name);
    localStorage.setItem("email", email);

    dispatch({
      type: CLEAR_SERVER_ERROR,
    });

    return response.data;
  } catch (e) {
    dispatch({
      type: SET_SERVER_ERROR,
      payload: { errors: serverErrorToErrorList(e) },
    });
    throw e;
  }
};

export const verifyEmail = (formdata) => async (dispatch) => {
  try {
    let response = await httpClient.put(`/email-verification`, formdata, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });

    return response.data;
  } catch (e) {
    dispatch({
      type: SET_SERVER_ERROR,
      payload: { errors: serverErrorToErrorList(e) },
    });
    throw e;
  }
};

export const deauthenticate = () => async (dispatch) => {
  localStorage.removeItem("token");
  localStorage.removeItem("name");
  localStorage.removeItem("email");

  dispatch({ type: DEAUTHENTICATE, payload: null });
};

export const register = (user) => async (dispatch) => {
  let response = await httpClient.post(`/register`, user, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });

  dispatch({ type: CLEAR_SERVER_ERROR });

  return response.data;
};
