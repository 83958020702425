import React, { Component } from "react";
import { connect } from "react-redux";
import { fetchPartnerList } from "../../redux/actions/partnerActions";
import PartnerListItem from "../common/PartnerListItem";

import withLayout from "../common/withLayout";
import { Link } from "react-router-dom";
import GetCode1_2x from "../../assets/images/landing_page/get_code_1@2x.png";
import Welle1 from "../../assets/images/landing_page/Welle-01.svg";
import Welle2 from "../../assets/images/landing_page/Welle-02.svg";
import Welle3 from "../../assets/images/landing_page/Welle-03.svg";
import Dune2 from "../../assets/images/landing_page/Dune-02.svg";
import Payment2x from "../../assets/images/landing_page/payment@2x.png";
import Order2x from "../../assets/images/landing_page/order@2x.png";
import CarouselSlide from "../common/CarouselSlide";

class Landing extends Component {
  componentDidMount() {
    this.props.fetchPartnerList();
  }

  nextPath(path) {
    this.props.history.push(path);
  }

  render() {
    const { partnerList } = this.props;

    return (
      <div className="landing-page">
        <section className="banner">
          <div className="container-box">
            <div className="content">
              <h2 class="bannerTitle">Service-Oase</h2>
              <h3 class="bannerTitle">Nicht nur Sand und Strand</h3>
              <h2 class="bannerTitle medium-text">List auf Sylt</h2>
            </div>
          </div>
        </section>
        <div className="landing-section-title title-bg-gray">
          <div className="inner">
            <div className="container">
              <div className="row">
                <div className="col-sm-12 col-md-11 col-lg-8 col-xl-7 light-bg">
                  <h2>... schau doch mal vorbei!</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        <section className="landing-intro light-bg">
          <div className="container">
            <div className="row">
              <div className="offset-sm-3 offset-lg-1 offset-xl-2" />
              <div className="col-sm-12 col-md-12 col-lg-10 col-xl-8">
                <h3>Einen Blick werfen</h3>
                <p>
                  Hier finden Sie einen Überblick der großen Vielfalt was List
                  auf Sylt zu bieten hat. Ob Einzelhandel, Gastronomie, Handwerk
                  oder Kultur.
                </p>
                <div className="light-bg">
                  <h2>Wir freuen uns auf Ihren Besuch</h2>
                </div>
              </div>
              <div className="offset-sm-3 offset-lg-1 offset-xl-2" />
            </div>
            <div className="col-sm-12">
              <img class="splashImages" src={Welle1} alt="" />
            </div>
          </div>
        </section>
        <section className="close-image-content">
          <div className="container">
            <div className="row">
              <div className="col-6 col-sm-5 col-md-6 col-lg-5 findShop">
                <h4>Finden Sie Ihre Möglichkeiten</h4>
                <Link to="/partners" className="landing-page-link">
                  <i className="icon-right-big-arrow" />
                </Link>
              </div>
              <div className="offset-sm-6"></div>
            </div>
          </div>
        </section>
        <section className="inner-banner inner-banner-1" />
        <div className="landing-section-title">
          <div className="inner bg-white">
            <div className="container">
              <div className="row">
                <div className="col-sm-12 col-md-8 col-lg-6 bg-white d-flex">
                  <div className="col-sm-6 col-md-6 col-lg-6">
                    <h3>Wie es funktioniert</h3>
                  </div>
                  <div className="col-sm-6 col-md-6 col-lg-6">
                    <img class="splashImages" src={Welle2} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <section class="steps-how-works bg-white">
          <div className="container landing-steps">
            <ul className="nav nav-tabs">
              <li className="active">
                <a data-toggle="tab" href="#order_voucher" className="active">
                  <i className="dot" />
                  <span>Coupon kaufen</span>
                </a>
              </li>
              <li>
                <a data-toggle="tab" href="#get_voucher_code">
                  <i className="dot" />
                  <span>Code erhalten</span>
                </a>
              </li>
              <li>
                <a data-toggle="tab" href="#payment">
                  <i className="dot" />
                  <span>Einlösen</span>
                </a>
              </li>
            </ul>

            <div className="tab-content voucher">
              <div id="order_voucher" className="tab-pane fade active show">
                <div className="row">
                  <div className="col-sm-6">
                    <h3>Coupon Kaufen</h3>
                    <p>
                      Durchstöbern Sie den Listinfo-Marketplace und lassen Sie
                      sich inspirieren! Alle hier aufgeführten
                      Einkaufsmöglichkeiten, Restaurants und Aktivitäten können
                      wir Ihnen wärmstens empfehlen.
                    </p>
                    <p>
                      List auf Sylt - steckt voller Möglichkeiten, die Ihren
                      Urlaub noch schöner machen! Falls Ihnen ein Angebot
                      zusagt, können Sie einen Coupon kaufen und direkt online
                      mit PayPal bezahlen!
                    </p>
                    <Link to="/partners" className="landing-page-link py-3">
                      <span>Zu den Shops</span>
                      <i className="icon-right-big-arrow" />
                    </Link>
                  </div>
                  <div className="col-sm-6">
                    <img src={Order2x} alt="" />
                  </div>
                </div>
              </div>
              <div id="get_voucher_code" className="tab-pane fade">
                <div className="row">
                  <div className="col-sm-6">
                    <h3>Code erhalten</h3>
                    <p>
                      Nachdem Sie den Coupon ausgewählt und bezhalt haben,
                      erhalten Sie ihn direkt als E-Mail. Sie müssen den Coupon
                      nicht ausdrucken. Es genügt, wenn Sie ihn vor Ort angeben.
                    </p>
                    <Link to="/partners" className="landing-page-link py-3">
                      <span>Zu den Shops</span>
                      <i className="icon-right-big-arrow" />
                    </Link>
                  </div>
                  <div className="col-sm-6">
                    <img src={GetCode1_2x} alt="get code" />
                  </div>
                </div>
              </div>
              <div id="payment" className="tab-pane fade">
                <div className="row">
                  <div className="col-sm-6">
                    <h3>Einlösen</h3>
                    <p>
                      Machen Sie einen Termin (falls nötig) oder schauen Sie bei
                      Ihrer Einkaufsmöglichkeit vor Ort vorbei. Nun können Sie
                      den erhaltenen Coupon ganz einfach einlösen. Dazu müssen
                      Sie nur den Coupon-Code vorlesen, schon ist er eingelöst!
                    </p>
                    <Link to="/partners" className="landing-page-link py-3">
                      <span>Zu den Shops</span>
                      <i className="icon-right-big-arrow" />
                    </Link>
                  </div>
                  <div className="col-sm-6">
                    <img src={Payment2x} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="inner-banner inner-banner-2" />
        <div className="landing-section-title title-bg-gray">
          <div className="inner">
            <div className="container">
              <div className="row">
                <div className="col-sm-6 col-md-8 col-lg-6 col-xl-5 light-bg">
                  <h2>Servicepartner</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        <section className="light-bg ">
          <div className="container">
            <div className="partners-logo">
              <h3>Partner</h3>
              <CarouselSlide shops={partnerList} />
            </div>
          </div>
        </section>
        <section className="landing-shop-tile">
          <div className="container">
            <div className="row w-100">
              <div className="col-sm-6 d-flex">
                <img class="splashImages3" src={Welle3} alt="" />
              </div>
              <div className="col-sm-6">
                <img class="splashImages4" src={Dune2} alt="" />
              </div>
            </div>
            <div className="row" style={{ justifyContent: "center" }}>
              <div className="card-box">
                {partnerList.slice(0, 4).map((partner) => (
                  <PartnerListItem key={partner.owner_id} data={partner} />
                ))}
              </div>
            </div>
            <div className="col-sm-12 justify-content-center toTopButton">
              <div className="btn-wrap">
                <button
                  type="button"
                  onClick={() => this.props.history.push("/partners")}
                  title="Alle partner"
                  className="btn btn-outline-primary"
                >
                  Zu den Partner-Shops
                </button>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

Landing = connect(
  (state) => ({
    partnerList: state.partnerList,
  }),
  {
    fetchPartnerList,
  }
)(Landing);

export default withLayout(Landing);
