import React, { Component } from "react";

class NoAccess extends Component {
  render() {
    return (
      <section className="error-page">
        <div className="container-fluid">
          <div className="row align-content-center">
            <div className="col-md-8 offset-md-2">
              <div className="tiled">
                <div className="tile-bg">
                  <h6 className="subtitle">No access to this page.</h6>
                  <p>You need to login in to access this page.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default NoAccess;
