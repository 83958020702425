import React, { Component } from "react";
import { connect } from "react-redux";

class ServerError extends Component {
  render() {
    return this.props.errors
      ? this.props.errors.map((error, index) => (
          <div key={index}>
            <span>
              <small className="text-danger">{error}</small>
            </span>
          </div>
        ))
      : null;
  }
}

export default connect((state) => ({
  errors: state.serverError.errors,
}))(ServerError);
