import { FETCH_CATEGORY_LIST } from "../actionTypes";

export const categoryListReducer = (state = [], action) => {
  switch (action.type) {
    case FETCH_CATEGORY_LIST:
      return action.payload;
    default:
      return state;
  }
};
