import React, { Component } from "react";
import { connect } from "react-redux";
import { fetchPartnerList } from "../../redux/actions/partnerActions";
import PartnerListItem from "../common/PartnerListItem";
import CategorySelector from "../common/CategorySelector";
import withLayout from "../common/withLayout";

class Partners extends Component {
  state = {
    partnerList: [],
  };

  async componentDidMount() {
    await this.props.fetchPartnerList({ all: true });
    this.setState({ partnerList: this.props.partnerList });
  }

  onCategoryChange = (categoryId) => {
    if (categoryId === 0) {
      this.setState({ partnerList: this.props.partnerList });
      return;
    }
    const categoryBasedPartnerList = this.props.partnerList.filter((partner) =>
      partner.categories.map((category) => category.id).includes(categoryId)
    );
    this.setState({ partnerList: categoryBasedPartnerList });
  };

  render() {
    const { partnerList } = this.state;
    return (
      <div className="partners-wrap">
        <div className="partners-title-wrap">
          <div className="container">
            <h2>Partner</h2>
          </div>
        </div>
        <section className="container partners-category">
          <div className="row">
            <div className="col-12">
              <CategorySelector onChange={this.onCategoryChange} />
            </div>
          </div>
          <div className="partner-tile-wrap">
            {partnerList.length === 0
              ? "Kein Shop verfügbar"
              : partnerList.map((partner) => (
                  <PartnerListItem key={partner.owner_id} data={partner} />
                ))}
          </div>
        </section>
      </div>
    );
  }
}

Partners = connect(
  (state) => ({
    partnerList: state.partnerList,
  }),
  {
    fetchPartnerList,
  }
)(Partners);

export default withLayout(Partners);
