import React, { Fragment } from "react";

export const inputField = ({
  input,
  placeholder,
  type,
  className,
  required,
  meta: { touched, error, warning },
}) => {
  return (
    <Fragment>
      <input
        {...input}
        type={type}
        placeholder={placeholder}
        className={className}
        required={required}
      />
      {touched &&
        ((error && (
          <span>
            <small className="form-text text-danger">{error}</small>
          </span>
        )) ||
          (warning && <span>{warning}</span>))}
    </Fragment>
  );
};
