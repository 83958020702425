import React, { Component } from "react";
import { connect } from "react-redux";
import { fetchCategoryList } from "../../redux/actions/categoryActions";

class CategorySelector extends Component {
  state = { selectedId: 0 };

  componentDidMount() {
    this.props.fetchCategoryList();
  }

  render() {
    const { categoryList } = this.props;
    return (
      <div>
        <div
          className="btn-group partner-category-tabs"
          role="group"
          aria-label="Category List"
        >
          <button
            type="button"
            className={
              this.state.selectedId === 0
                ? "btn btn-primary"
                : "btn btn-outline-primary"
            }
            onClick={() => {
              this.setState({ selectedId: 0 });
              this.props.onChange(0);
            }}
          >
            Alle Kategorien
          </button>
          {categoryList.map((category) => (
            <button
              type="button"
              className={
                this.state.selectedId === category.id
                  ? "btn btn-primary"
                  : "btn btn-outline-primary"
              }
              key={category.id}
              onClick={() => {
                this.setState({ selectedId: category.id });
                this.props.onChange(category.id);
              }}
            >
              {category.name}
            </button>
          ))}
        </div>
      </div>
    );
  }
}

CategorySelector = connect(
  (state) => ({
    categoryList: state.categoryList,
  }),
  {
    fetchCategoryList,
  }
)(CategorySelector);

export default CategorySelector;
