import React, { Component } from "react";
import { Link, NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { deauthenticate } from "../../redux/actions/authActions";
import Logo from "../../assets/icons/Logo.svg";
import PartnerIcon from "../../assets/icons/Partner.svg";
import LoginIcon from "../../assets/icons/login.svg";
import CouponIcon from "../../assets/icons/Coupon.svg";

class Header extends Component {
  logout = (e) => {
    e.preventDefault();
    this.props.deauthenticate().then(() => {
      this.props.history.push("/");
    });
  };

  render() {
    return (
      <header>
        <nav className="navbar navbar-expand-lg">
          <div className="container">
            <div className="col-sm-12 col-md-6 col-lg-6">
              <NavLink className="navbar-brand" to="/">
                <img src={Logo} alt="Logo" />
              </NavLink>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6">
              <div className="navheader d-flex align-items-center">
                <div
                  className="navbar-collapse d-flex justify-content-end flex-row"
                  id=""
                >
                  <ul className="nav main-menu">
                    <li className="nav-item">
                      <NavLink
                        activeClassName="active"
                        className="nav-link"
                        to="/partners"
                      >
                        Partner
                        <img
                          className="navIcons fill-blue"
                          src={PartnerIcon}
                          alt="Partner"
                        />
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink
                        activeClassName="active"
                        className="nav-link"
                        to="/coupon"
                      >
                        Coupon
                        <img
                          className="navIcons fill-blue"
                          src={CouponIcon}
                          alt="Coupon"
                        />
                      </NavLink>
                    </li>
                    {!this.props.authentication ? (
                      <li className="nav-item">
                        <Link className="nav-link user-block" to="/login">
                          Einloggen
                          <img
                            className="navIcons fill-blue"
                            src={LoginIcon}
                            alt="Login"
                          />
                        </Link>
                      </li>
                    ) : (
                      <li className="dropdown d-flex align-items-center">
                        <Link
                          className="app-nav__item dropdown-toggle header-dropdown"
                          to=""
                          data-toggle="dropdown"
                          aria-label="Open Profile Menu"
                        >
                          <span className="nav-link">
                            {this.props.authentication.name}
                          </span>
                          <i className="icon-account"> </i>
                        </Link>
                        <ul className="dropdown-menu settings-menu dropdown-menu-right">
                          <li>
                            <Link
                              className="dropdown-item"
                              to={"/change-password"}
                            >
                              <i className="icon-password"> </i> Passwort ändern
                            </Link>
                          </li>
                          <li>
                            <Link className="dropdown-item" to={"/my-coupons"}>
                              <i className="icon-user-o"> </i> Meine Coupons
                            </Link>
                          </li>
                          <li>
                            <Link
                              className="dropdown-item"
                              to=""
                              onClick={this.logout}
                            >
                              <i className="icon-exit"> </i> Ausloggen
                            </Link>
                          </li>
                        </ul>
                      </li>
                    )}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </header>
    );
  }
}

export default connect(
  (state) => ({
    authentication: state.authentication,
  }),
  {
    deauthenticate,
  }
)(Header);
