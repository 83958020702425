import React, { Component, Fragment } from "react";
import Header from "./Header";
import { Link } from "react-router-dom";
import footerIcon from "../../assets/icons/footerIcon-1.svg";
import footerEmailIcon from "../../assets/icons/footer-email-Icon.svg";
export default (ChildComponent) =>
  class ComposedComponent extends Component {
    render() {
      return (
        <Fragment>
          <Header location={this.props.location} history={this.props.history} />
          <div class="wrapper-child">
            <ChildComponent {...this.props} />
          </div>
          <footer>
            <div className="container">
              <div className="row">
                <div className="col-lg-5 col-md-4 col-sm-12 order-lg-1">
                  <ul className="nav justify-content-lg-end footer-links justify-content-md-center">
                    <li>
                      <Link to="/data-protection">Datenschutz</Link>
                    </li>
                    <li>
                      <Link to="/imprint">Impressum</Link>
                    </li>
                  </ul>
                </div>
                <div className="col-lg-7 col-md-8 col-sm-12 order-lg-0 footer_info">
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <img className="footerIcons" src={footerIcon} alt="" />
                    <span>© 2022 list-marketplace.de</span>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <img className="footerIcons" src={footerEmailIcon} alt="" />
                    <span className="ml-2">ahoi@list-marketplace.de</span>
                  </div>
                </div>
              </div>
            </div>
          </footer>
        </Fragment>
      );
    }
  };
