import React, { Component } from "react";
import moment from "moment";
import { connect } from "react-redux";
import withLayout from "../common/withLayout";
import { fetchCouponList } from "../../redux/actions/couponActions";
import NoAccess from "../common/NoAccess";
import ReactTooltip from "react-tooltip";
import Pagination from "react-js-pagination";

class MyCoupons extends Component {
  state = {
    page: 1,
    code: "",
    value: "",
    status: [],
    created_on_from: null,
    created_on_to: null,
    sold_on_to: null,
    sold_on_from: null,
    showConfirmationModal: false,
    code_to_be_redeemed: null,
    general_user_id: null,
  };

  resetFilter = async (event) => {
    event.preventDefault();
    await this.setState({
      page: 1
    });
    this.search();
  };
  
  async componentDidMount() {
    if (this.props.authentication)
    await this.setState({ general_user_id: this.props.authentication.user_id });
    this.search();
      // this.props.fetchCouponList({
      //   general_user_id: this.props.authentication.user_id,
      // });
  }

  search = () => {
    const data = {
      page: this.state.page,
      general_user_id: this.state.partner_id,
    };

    this.props.fetchCouponList(data);
  };

  getStatusColor = (status) => {
    let className = null;
    switch (status) {
      case "active":
        className = "status status-active";
        break;
      case "inactive":
        className = "status status-inactive";
        break;
      case "expired":
        className = "status status-expired";
        break;
      case "redeemed":
        className = "status status-redeemed";
        break;
      default:
        className = "";
    }

    return className;
  };
 getStatusTranslation = (status) => {
    let translated = null;
    switch (status) {
      case "active":
        translated = "Aktiv";
        break;
      case "redeemed":
        translated = "Eingelöst";
        break;
      default:
        translated = "";
    }

    return translated;
  };
  render() {

    const { couponList, authentication  } = this.props;

    return authentication ? (
      <section className="my-coupons">
        <div className="my-coupon-title-wrap">
          <div className="container">
            <h2>Meine Coupons</h2>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-sm-12 table-responsive">
              <table className="table">
                <thead className="thead-light">
                  <tr>
                    <th>Coupon</th>
                    <th>Name des Shops</th>
                    <th>Betrag</th>
                    <th className="text-center">Erstellt am</th>
                    <th className="text-center">
                      <span data-tip data-for="statuses">
                        Status
                      </span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {couponList.data.map((code, index) => (
                    <tr key={index} className={code.status + "-row"}>
                      <td className="text-uppercase">{code.code}</td>
                      <td>{code.order_item.order.partner.shop_name}</td>
                      <td>€{code.value}</td>
                      <td className="text-center">
                        {code.created_at
                          ? moment(code.created_at).format("DD.MM.YYYY")
                          : ""}
                      </td>
                      <td>
                        <span
                          className={this.getStatusColor(code.status)}
                          data-tip
                          data-for={"status_" + index}
                        >
                          <ReactTooltip id={"status_" + index}>
                            {this.getStatusTranslation(code.status)}
                          </ReactTooltip>
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <Pagination
                itemClass="page-item"
                linkClass="page-link"
                activeClass="active"
                activeLinkClass=""
                disabledClass="disabled"
                prevPageText="Vorherige"
                nextPageText="Nächste"
                firstPageText="Erste"
                lastPageText="Letzte"
                activePage={this.state.page}
                itemsCountPerPage={(couponList.meta)?couponList.meta.per_page:0}
                totalItemsCount={(couponList.meta)?couponList.meta.total:0}
                pageRangeDisplayed={5}
                onChange={async (page) => {
                  await this.setState({ page: page });
                  this.search();
                }}
              />
            </div>
          </div>
        </div>
      </section>
    ) : (
      <NoAccess />
    );
  }
}

MyCoupons = connect(
  (state) => ({
    couponList: state.couponList,
    authentication: state.authentication,
  }),
  { fetchCouponList }
)(MyCoupons);

export default withLayout(MyCoupons);