import React, { Component } from "react";
import { inputField } from "../redux_form_fields/inputField";
import {
  required,
  requiredAndEmail,
  requiredAndPassword,
} from "../../services/validate";
import ServerError from "../common/ServerError";
import { Field, reduxForm, SubmissionError } from "redux-form";
import { register } from "../../redux/actions/authActions";
import { connect } from "react-redux";
import { withAlert } from "react-alert";
import { Link, NavLink } from "react-router-dom";
import bgImage from "../../assets/images/login-image-marketPalace.png";
import Logo from "../../assets/icons/Logo.svg";

class Register extends Component {
  onSubmit = async (formProps) => {
    formProps.role = "general_user";

    try {
      await this.props.register(formProps);
      this.props.alert.show(
        "Erfolgreich registriert. Bitte prüfen sie ihr e-mail Postfach.",
        { type: "success" }
      );
      this.props.history.push("/login");
    } catch (e) {
      if (e.isAxiosError) {
        if (e?.response?.data?.errors) {
          throw new SubmissionError(e.response.data.errors);
        }
        if (e?.response?.data?.message) {
          this.props.alert.show(e?.response?.data?.message, { type: "error" });
          return;
        }
      }

      this.props.alert.show(e.message, { type: "error" });
    }
  };

  render() {
    const { handleSubmit } = this.props;
    return (
      <section className="login-page">
        <div className="login-logo">
          <NavLink to="/">
            <img src={Logo} alt="Logo" width="300px" />
          </NavLink>
        </div>
        <div className="container">
          <div className="auth-block">
            <h5 className="auth-title">Registrieren</h5>
            <form onSubmit={handleSubmit(this.onSubmit)}>
              <div className="form-group">
                <label>Benutzername</label>
                <Field
                  className="form-control"
                  type="text"
                  component={inputField}
                  name="name"
                  validate={required}
                />
              </div>

              <div className="form-group">
                <label>E-mail Adresse</label>
                <Field
                  className="form-control"
                  type="text"
                  component={inputField}
                  name="email"
                  validate={requiredAndEmail}
                />
              </div>
              <div className="form-group">
                <label>Passwort</label>
                <Field
                  className="form-control"
                  type="password"
                  component={inputField}
                  name="password"
                  validate={requiredAndPassword}
                />
              </div>
              <div className="form-group">
                <label>Passwort Bestätigen</label>
                <Field
                  className="form-control"
                  type="password"
                  component={inputField}
                  name="password_confirmation"
                  validate={required}
                />
              </div>
              <div className="form-group">
                <ServerError />
              </div>
              <div className="form-group">
                <button className="btn btn-primary btn-block" type="submit">
                  Account erstellen
                </button>
              </div>
              <div className="form-group text-center">
                Sie haben bereits eine Account?
                <Link to="/login">Einloggen</Link>
              </div>
              <p className="small text-center">
                Indem Sie fortfahren erklären Sie sich mit unseren
                Nutzungsbedingungen und der Datenschutzerklärung einverstanden.
              </p>
            </form>
          </div>
          <div className="login-image">
            <img src={bgImage} alt="background-img" />
          </div>
        </div>
      </section>
    );
  }
}

Register = reduxForm({
  form: "register",
})(Register);

export default connect(null, {
  register,
})(withAlert()(Register));
