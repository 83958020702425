import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { hideFlashMessage } from "../../redux/actions/flashMessageActions";

class FlashMessage extends Component {
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.flashMessage !== prevProps.flashMessage) {
      setTimeout(() => {
        this.props.hideFlashMessage();
      }, 5000);
    }
  }

  render() {
    return this.props.flashMessage.show ? (
      <Fragment>
        <div
          className={`alert alert-${this.props.flashMessage.color}`}
          role="alert"
        >
          {this.props.flashMessage.message}
        </div>
      </Fragment>
    ) : null;
  }
}

export default connect(
  (state) => ({
    flashMessage: state.flashMessage,
  }),
  {
    hideFlashMessage,
  }
)(FlashMessage);
