import React, { Component } from "react";
import { connect } from "react-redux";
import { authenticate } from "../../redux/actions/authActions";
import { inputField } from "../redux_form_fields/inputField";
import { required, requiredAndEmail } from "../../services/validate";
import { Field, reduxForm } from "redux-form";
import ServerError from "../common/ServerError";
import { Link, NavLink } from "react-router-dom";
import Logo from "../../assets/icons/Logo.svg";
import bgImage from "../../assets/images/login-image-marketPalace.png";

class Login extends Component {
  onSubmit = (formProps) => {
    formProps.role = "general_user";
    this.props.authenticate(formProps).then(
      (response) => {
        this.props.history.push("/coupon");
      },
      (errorResponse) => {}
    );
  };

  render() {
    const { handleSubmit } = this.props;
    const { emailVerifiedMessage } = this.props.emailVerification;

    return (
      <section className="login-page">
        <div className="login-logo">
          <NavLink to="/">
            <img src={Logo} alt="Logo" width="300px" />
          </NavLink>
        </div>
        <div className="container">
          <div className="auth-block">
            {emailVerifiedMessage && (
              <div className="alert alert-success" role="alert">
                Vielen Dank für die Bestätigung Ihrer E-Mail-Adresse.
              </div>
            )}
            <h5 className="auth-title">Einloggen</h5>
            <form onSubmit={handleSubmit(this.onSubmit)}>
              <div className="form-group">
                <label>E-Mail Adresse</label>
                <Field
                  className="form-control"
                  type="text"
                  component={inputField}
                  name="email"
                  validate={requiredAndEmail}
                  placeholder="E-Mail"
                />
              </div>
              <div className="form-group">
                <label>Passwort</label>
                <Field
                  className="form-control"
                  type="password"
                  component={inputField}
                  name="password"
                  validate={required}
                  placeholder="passwort"
                />
              </div>
              <div className="form-group">
                <ServerError />
              </div>
              <div className="form-group text-right">
                <label className="form-check-label">
                  <Link to="/forgot-password"> Passwort vergessen? </Link>
                </label>
              </div>
              <div className="form-group">
                <button className="btn btn-primary btn-block" type="submit">
                  Einloggen
                </button>
              </div>
              <div className="form-group text-center">
                Noch kein Account?
                <Link to="/register"> Jetzt Registrieren</Link>
              </div>
              <p className="small text-center">
                Indem Sie fortfahren erklären Sie sich mit unseren
                Nutzungsbedingungen und der Datenschutzerklärung einverstanden.
              </p>
            </form>
          </div>
          <div className="login-image">
            <img src={bgImage} alt="background-img" />
          </div>
        </div>
      </section>
    );
  }
}

Login = reduxForm({
  form: "login",
})(Login);

const mapStateToProps = (state) => {
  return {
    emailVerification: state.flashMessage,
  };
};

export default connect(mapStateToProps, {
  authenticate,
})(Login);
