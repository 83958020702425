import React, { Component } from "react";

class NotFound404 extends Component {
  render() {
    return (
      <section className="error-page">
        <div className="container-fluid">
          <div className="row align-content-center">
            <div className="col-md-8 offset-md-2">
              <div className="tiled">
                <div className="tile-bg">
                  <h1 className="title">404</h1>
                  <h6 className="subtitle">OOPS! page not found.</h6>
                  <p>We can't seem to find the page you're looking for.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default NotFound404;
