import React, { Component, Fragment } from "react";
import withLayout from "../common/withLayout";

class Notification extends Component {
  render() {
    const { state } = this.props.location;

    return (
      <Fragment>
        <div>
          <div className="coupon-title-wrap">
            <div className="container">
              <h2>Benachrichtigung</h2>
            </div>
            <i className="icon-coupon"></i>
          </div>

          {state ? (
            <section className="container coupon-wrap">
              <div className="row">
                <div className="col-sm-12">
                  <h5>{state.title}</h5>
                  <table className="table table-borderless">
                    <tbody>
                      {state.message.map((voucher) => (
                        <tr key={voucher.code} className="text-center">
                          <td>{voucher.code}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </section>
          ) : (
            <section className="container coupon-wrap">
              <div className="row">
                <div className="col-sm-12 text-center">
                  Momentan keine Benachrichtigung verfügbar.
                </div>
              </div>
            </section>
          )}
        </div>
      </Fragment>
    );
  }
}

export default withLayout(Notification);
