import React, { Component } from "react";
import { connect } from "react-redux";
import {
  getStripePaymentIntent,
  placeGuestUserOrder,
  placeOrder,
} from "../../redux/actions/orderActions";
import httpClient from "../../services/httpClient";
import withLayout from "../common/withLayout";

class Checkout extends Component {
  state = {
    order: {
      partner: {},
      items: [],
    },
  };

  componentDidMount() {
    const { state } = this.props.location;
    const { placeOrder, placeGuestUserOrder, authentication, history } =
      this.props;

    if (!state) history.push("/coupon");

    this.setState({ order: state });

    window.paypal.Button.render(
      {
        env: "sandbox", // Or 'production'
        style: {
          size: "large",
          color: "gold",
          shape: "pill",
        },
        payment: function () {
          return httpClient
            .post("/create-paypal-transaction", state)
            .then(function (res) {
              return res.data;
            })
            .then(function (data) {
              return data.id;
            });
        },
        onAuthorize: function (data, actions) {
          return httpClient
            .post("/confirm-paypal-transaction", {
              payment_id: data.paymentID,
              payer_id: data.payerID,
            })
            .then(function (res) {
              const order = authentication ? placeOrder : placeGuestUserOrder;

              order({
                ...state,
                paypal_payment_id: data.paymentID,
                paypal_payer_id: data.payerID,
              }).then((response) => {
                history.replace("/checkout", null);
                history.push({
                  pathname: "/notification",
                  state: {
                    title: "Order completed. Your purchased coupons.",
                    message: response,
                  },
                });
              });
            });
        },
      },
      "#paypal-button"
    );
  }

  render() {
    const { order } = this.state;

    return (
      <div>
        <div className="coupon-title-wrap">
          <div className="container">
            <h2>Kasse</h2>
          </div>
          <i className="icon-coupon"></i>
        </div>

        <section className="container coupon-wrap">
          <div className="row">
            <div className="col-sm-12">
              <h5>Zusammenfassung</h5>

              <table className="table table-borderless">
                <tbody>
                  <tr>
                    <td>Name des Shop</td>
                    <td>{order.partner.shop_name}</td>
                  </tr>
                  {order.items.map((item, index) => (
                    <tr key={index}>
                      <td>{item.quantity} X voucher</td>
                      <td>{item.coupon_value} Euro</td>
                    </tr>
                  ))}
                  <tr className="total-coupon-amount">
                    <td>
                      <b>Gesamt</b>
                    </td>
                    <td>
                      <b>{order.total} Euro</b>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="row">
            <div className="col-12 paypal-col">
              <div id="paypal-button"></div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

Checkout = connect(
  (state) => ({
    authentication: state.authentication,
  }),
  {
    placeOrder,
    placeGuestUserOrder,
    getStripePaymentIntent,
  }
)(Checkout);

export default withLayout(Checkout);
