import {
  FETCH_PARTNER_DETAIL,
  FETCH_PARTNER_LIST,
  SET_SERVER_ERROR,
} from "../actionTypes";
import httpClient, { serverErrorToErrorList } from "../../services/httpClient";

export const fetchPartnerList =
  (params = {}) =>
  async (dispatch) => {
    params.city_code = process.env.REACT_APP_CITY_CODE;

    try {
      let response = await httpClient.get(`/partners`);

      dispatch({
        type: FETCH_PARTNER_LIST,
        payload: response.data.data,
      });

      return response.data.data;
    } catch (e) {
      dispatch({
        type: SET_SERVER_ERROR,
        payload: { errors: serverErrorToErrorList(e) },
      });
      throw e;
    }
  };

export const fetchPartnerDetail = (id) => async (dispatch) => {
  try {
    let response = await httpClient.get(`/partners/${id}`);

    dispatch({
      type: FETCH_PARTNER_DETAIL,
      payload: response.data.data,
    });

    return response.data.data;
  } catch (e) {
    dispatch({
      type: SET_SERVER_ERROR,
      payload: { errors: serverErrorToErrorList(e) },
    });
    throw e;
  }
};
