import { applyMiddleware, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import reducer from "./reducers";
import { AUTHENTICATE } from "./actionTypes";
import jwt from "jsonwebtoken";

const store = createStore(
  reducer,
  {},
  composeWithDevTools(applyMiddleware(thunk))
);

/**
 * syncing localStorage token to redux state
 */
const token = localStorage.getItem("token");
const name = localStorage.getItem("name");
const email = localStorage.getItem("email");
if (token) {
  const { sub } = jwt.decode(token);

  store.dispatch({
    type: AUTHENTICATE,
    payload: { token, name, email, user_id: sub },
  });
}

export default store;
