import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { changePassword } from "../../redux/actions/passwordActions";
import { showFlashMessage } from "../../redux/actions/flashMessageActions";
import ServerError from "../common/ServerError";
import { deauthenticate } from "../../redux/actions/authActions";
import withLayout from "../common/withLayout";
import { inputField } from "../redux_form_fields/inputField";
import { required, requiredAndPassword, same } from "../../services/validate";
import NoAccess from "../common/NoAccess";

class ChangePassword extends Component {
  handleSubmit = (formProps) => {
    this.props.changePassword(formProps).then((response) => {
      this.props.deauthenticate().then(() => {
        this.props.history.push("/login");
        this.props.showFlashMessage(response.message);
      });
    });
  };

  render() {
    const { handleSubmit, authentication } = this.props;
    return authentication && authentication.token ? (
      <section className="login-page">
        <div className="container">
          <div className="auth-block">
            <h5>Password ändern </h5>
            <form onSubmit={handleSubmit(this.handleSubmit)}>
              <div className="form-group">
                <label>Altes Passwort</label>
                <Field
                  component={inputField}
                  className="form-control"
                  type="password"
                  name="old_password"
                  validate={required}
                />
              </div>
              <div className="form-group">
                <label>Neues Passwort</label>
                <Field
                  component={inputField}
                  className="form-control"
                  type="password"
                  name="new_password"
                  validate={requiredAndPassword}
                />
              </div>
              <div className="form-group">
                <label>Neues Passwort bestätigen</label>
                <Field
                  component={inputField}
                  className="form-control"
                  type="password"
                  name="new_password_confirmation"
                  validate={same}
                />
              </div>
              <div className="form-group">
                <ServerError />
              </div>
              <div className="form-group">
                <button className="btn btn-primary btn-block btn-password-change" type="submit">
                  Password ändern
                </button>
              </div>
            </form>
          </div>
        </div>
      </section>
    ) : (
      <NoAccess />
    );
  }
}

ChangePassword = reduxForm({
  form: "change-password",
})(ChangePassword);

ChangePassword = connect(
  (state) => ({
    authentication: state.authentication,
  }),
  {
    changePassword,
    showFlashMessage,
    deauthenticate,
  }
)(ChangePassword);
export default withLayout(ChangePassword);
