import React, { Component } from "react";
import { inputField } from "../redux_form_fields/inputField";
import { requiredAndPassword } from "../../services/validate";
import ServerError from "../common/ServerError";
import { Field, reduxForm } from "redux-form";
import qs from "qs";

class Reset extends Component {
  handleSubmit = (formProps) => {
    formProps.token = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    }).token;
    this.props.resetPassword(formProps).then((response) => {
      this.props.history.push("/login");
      this.props.showFlashMessage(
        "Passwort erfolgreich zurückgesetzt. Bitte einloggen zum Fortfahren."
      );
    });
  };

  render() {
    const { handleSubmit } = this.props;

    return (
      <section className="login-page">
        <div className="container">
          <div className="auth-block">
            <h5 className="auth-title">Password änderen</h5>
            <form onSubmit={handleSubmit(this.handleSubmit)}>
              <div className="form-group">
                <label>Neues Passwort</label>
                <Field
                  component={inputField}
                  className="form-control"
                  type="password"
                  name="password"
                  validate={requiredAndPassword}
                />
              </div>
              <div className="form-group">
                <label>Neues Passwort bestätigen</label>
                <Field
                  component={inputField}
                  className="form-control"
                  type="password"
                  name="password_confirmation"
                  validate={requiredAndPassword}
                />
              </div>
              <div className="form-group">
                <ServerError />
              </div>
              <div className="form-group">
                <button className="btn btn-primary btn-block" type="submit">
                  Passwort ändern
                </button>
              </div>
            </form>
          </div>
        </div>
      </section>
    );
  }
}

Reset = reduxForm({
  form: "reset-password",
})(Reset);

export default Reset;
